<template>
    <div class="d-flex" :class="{'eod-btn-dropdown': dropdown}">
        <v-btn :id="$attrs.label?'button_'+$helper.slugify($attrs.label):null" rounded depressed :color="$attrs.color||'primary'" v-on="$listeners" v-bind="$attrs">
            <template v-slot:default="scope">
                <v-icon v-if="$attrs['mdi-icon']" left>{{ $attrs['mdi-icon'] }}</v-icon>
                <slot name="default" v-bind="{...scope}">
                    {{ $attrs.label }}
                </slot>
            </template>
        </v-btn>
        <v-menu offset-y left rounded="xl">
            <template v-slot:activator="{attrs, on}">
                <v-btn v-bind="attrs" v-on="on" v-if="dropdown" :color="($attrs.color||'primary')+' lighten-2'" depressed rounded><v-icon>mdi-chevron-down</v-icon></v-btn>
            </template>
                <slot name="dropdown"></slot>
        </v-menu>
    </div>
</template>
<style lang="scss" scoped>
    .eod-btn-dropdown{
        > .v-btn:first-child{
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        > .v-btn:last-child{
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
</style>
<script>
    export default {
        name: 'eod-btn',
        props:{
            dropdown: {
                type: Boolean,
                default: false
            }
        }
    }
</script>