<template>
    <eod-graph v-bind="{...$props, ...$attrs}" v-on="$listeners" @reload="reload" :alarms="alarms" :series="series" @exportData="exportData" :loading="loading">
        <template v-slot:default="{contextmenu, mouseup}">
            <div class="flex-grow-1" :class="inset?'px-6 pb-4':'px-1 py-1'">
                <e-charts 
                    class="w-100 h-100"
                    :ref="id"
                    :initOptions="options"
                    :autoresize="true"
                    :manualUpdate="true"
                    @finished="finished"
                    group="eod-line-graph"
                    @datazoom="zoom"
                    @contextmenu="contextmenu"
                    @zr:contextmenu="contextmenu"
                    @mousemove="mousemove"
                    @mouseup="params => mouseup(params, options.series[params.seriesIndex])"
                />
            </div>
        </template>
    </eod-graph>
</template>
<script>
import ECharts from './echarts/ECharts';
import graphMixin from './mixins/graph.js';
import AlarmRule from './../../models/alarmRule.js'
import Event from './../../models/event.js'
import moment from 'moment'

export default {
    name: 'eod-graph-line',
    components:{
        ECharts,
    },
    mixins:[graphMixin],
    props: {
        inset: {
            type: Boolean,
            default: true
        }
    },
    data: () => ({
        options: {
            animation: false,
            tooltip:{
                show: true,
                trigger: 'axis',
                confine:true,
                axisPointer: {
                    axis: 'x'
                },
                formatter: function (params) {
                    let label = '';
                    if(params[0]){
                        label += '<strong>'+moment(params[0].value[0]).format('DD/MM/YYYY HH:mm') + '</strong><br>';
                        for (let i = 0; i < params.length; i++) {
                            const param = params[i];
                            label += param.marker + param.seriesName + ': <strong>' + param.value[1];

                            if(param.value[2] && param.value[2].unit){
                                if(param.value[2].unit.fields && param.value[2].unit.fields[2]){
                                    label += ' ' + param.value[2].unit.fields[2].name;
                                } else {
                                    label += ' ' + param.value[2].unit.name;
                                }
                            }
                            
                            label += '</strong><br>';
                        }
                    }
                    
                    
                    return label;
                }
            },
            toolbox: {
                feature: {
                    dataZoom: {
                        show: true,
                        yAxisIndex: 'none',
                        icon: {
                            zoom: 'image://',
                            back: 'image://'
                        },
                        brushStyle: {
                            color: "#000000",
                            opacity: 0.3,
                        }
                    }
                }
            },
            legend: {
                show:false,
                type: 'scroll',
                pageButtonPosition: 'start'
            },
            grid: {
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
                containLabel: true,
            },
            series: [],
        }
    }),
    mounted(){
        this.reload();
    },
    methods:{
        reload(){
            this.loadData().then(() => {
                return this.update();
            });
        },
        mousemove(params) {
            if (this.$refs[this.id] && params.componentType == 'series') {
                this.$refs[this.id].chart.getZr().setCursorStyle('pointer');
            }
        },
        async update(){
            
            if(this.value.series[0]){
                
                if(this.value.series[0] && this.value.series[0].xAxis && this.value.series[0].yAxis){
                    this.options.xAxis = this.getAxisPropsByFieldKey(this.value.series[0].xAxis);
                    this.options.yAxis = this.getAxisPropsByFieldKey(this.value.series[0].yAxis);
                }

                this.mergeTileOptions();

                this.options.series = this.getSeries();

                this.options.grid.top = 10;
                if(this.options.legend.show){
                    this.options.grid.top += 20;
                }

                // Add events on line
                if(this.value.graph.showEvents){
                    await this.loadEvents();
                    if(this.options.xAxis.type == 'time'){
                        for (let i = 0; i < this.options.series.length; i++) {
                            const markPoints = [];
                            if(this.serieEvents[i]){
                                for (let j = 0; j < this.serieEvents[i].length; j++) {
                                    const event = this.serieEvents[i][j];

                                    markPoints.push({
                                        itemStyle: {
                                            color: Event.getSeverity(event.severity).color
                                        },
                                        name: event.message,
                                        xAxis: event.time,
                                        yAxis: event.value,
                                        eventId: event.id
                                    });

                                    this.options.series[i].data.push({
                                        value: [event.time, event.value]
                                    });
                                }

                                this.options.series[i].markPoint = {
                                    symbolSize: 18,
                                    symbol: "circle",
                                    data: markPoints
                                }
                            }
                        }
                    }
                }
                
                if(this.value.graph.showAlarmBoundries){
                    await this.loadAlarmTemplates();
                    if(this.alarmTemplates[0]){
                        const alarmTemplate = this.alarmTemplates[0];
                        this.options.series[0].markArea = {
                                silent:true,
                                itemStyle:{
                                    opacity: 0.1
                                },
                                data: []
                            }

                            const data = [];

                            for (let i = 0; i < AlarmRule.severities.length; i++) {
                                const severity = AlarmRule.severities[i];
                                let min = null;
                                if(alarmTemplate.content.severities[severity.value]){

                                    switch(severity.value){
                                        case 'criticalLow':
                                            data.push([
                                                {y:'min', itemStyle:{color:severity.hex}},
                                                {yAxis: parseFloat(alarmTemplate.content.severities[severity.value])}
                                            ]);
                                            break;
                                        case 'warningLow':
                                        case 'infoLow':
                                            min = data[data.length-1]?data[data.length-1][1]:{y:'min'};
                                            min.itemStyle = {color:severity.hex};
                                            data.push([
                                                min,
                                                {yAxis: parseFloat(alarmTemplate.content.severities[severity.value])}
                                            ]);
                                            break;
                                        case 'ok':
                                            min = data[data.length-1]?data[data.length-1][1]:{y:'min'};
                                            min.itemStyle = {color:severity.hex};
                                            data.push([
                                                min,
                                                {y:'max', itemStyle:{color:severity.hex}}
                                            ]);
                                            break;
                                        case 'infoHigh':
                                        case 'warningHigh':
                                        case 'criticalHigh':
                                            if(data[data.length-1]){
                                                data[data.length-1][1] = {yAxis: parseFloat(alarmTemplate.content.severities[severity.value])};
                                            }
                                            data.push([
                                                {yAxis: parseFloat(alarmTemplate.content.severities[severity.value])},
                                                {y:'max', itemStyle:{color:severity.hex}}
                                            ]);
                                            break;
                                    }

                                    //prev = severity.value;
                                }
                            }

                            this.options.series[0].markArea.data = data;
                    }
                }

                if(this.$refs[this.id]){
                    this.$refs[this.id].mergeOptions(this.options);
                    this.$nextTick(() => {
                        this.$refs[this.id].dispatchAction({
                            type: 'takeGlobalCursor',
                            key: 'dataZoomSelect',
                            dataZoomSelectActive: true
                        });
                    })
                }
            }

            this.$forceUpdate();
            
        },
        
    }
}
</script>