import Vue from 'vue'
import App from './App.vue'
import CleanApp from './CleanApp.vue'
//import './registerServiceWorker'
import router from './router'
import i18n from './i18n'
import { eod, eodShare, helper, Translator, vuetify, getEnv, validator, globalComponents } from './eodvuecomponents';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import 'leaflet/dist/leaflet';
import 'leaflet.markercluster/dist/leaflet.markercluster.js';
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import moment from 'moment-timezone';
import Toaster from './plugins/toaster'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css';
import VueLogger from 'vuejs-logger';
import VCalendar from 'v-calendar';
import VueClipboard from 'vue-clipboard2'

import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

import VueScrollPicker from "vue-scroll-picker";
import "vue-scroll-picker/dist/style.css";

import EODWebSocket from './eodvuecomponents/src/classes/websocket'
import DashboardResolver from './eodvuecomponents/src/classes/dashboardResolver'

const isProduction = getEnv('NODE_ENV') === 'production';

if (isProduction) {
  Bugsnag.start({
    apiKey: 'b554503a2d10012b6d14e7fe77b0c660',
    plugins: [new BugsnagPluginVue()],
    appVersion: process.env.VUE_APP_VERSION
  });

  const bugsnagVue = Bugsnag.getPlugin('vue');
  bugsnagVue.installVueErrorHandler(Vue);

  Vue.prototype.$bugsnag = Bugsnag;
}

// Load keycloak script
let keycloakScript = document.createElement('script');
keycloakScript.setAttribute('src', getEnv('VUE_APP_KEYCLOAK_AUTH_URL') + '/js/keycloak.js');
document.head.appendChild(keycloakScript);

document.body.onload = initKeycloak;

let keycloak = null;

function initKeycloak() {
  var realm = eod.getRealm();

  var config = {
    realm: realm,
    url: getEnv('VUE_APP_KEYCLOAK_AUTH_URL'),
    clientId: getEnv('VUE_APP_KEYCLOAK_CLIENT_ID'),
  };

  keycloak = new Keycloak(config);

  keycloak.init({
    onLoad: 'login-required',
    pkceMethod: 'S256',
    promiseType: 'native'
  }).then(function (authenticated) {
    if (authenticated) {
      eod.loginUser(keycloak.token).then((user) => {
        initApp(keycloak);
      });
    }
  }).catch(function (e) {
    console.error('failed to initialize', e);
  });
}

async function initApp(keycloak) {

  const options = {
    isEnabled: true,
    logLevel: isProduction ? 'error' : 'debug',
    stringifyArguments: false,
    showLogLevel: true,
    showMethodName: true,
    separator: '|',
    showConsoleColors: true
  };

  moment.locale('nl');

  Vue.use(VueClipboard);
  Vue.use(VueScrollPicker);
  Vue.use(VueLogger, options);

  // Load eod global components
  for (let i = 0; i < globalComponents.length; i++) {
    const component = globalComponents[i];
    Vue.component(component.name, component);
  }

  Vue.use(TiptapVuetifyPlugin, {
    vuetify,
    iconsGroup: 'mdi'
  });

  Vue.use(VCalendar, {
    componentPrefix: 'vc', // Use <vc-calendar /> instead of <v-calendar />
  });

  Vue.config.productionTip = false;

  const translator = new Translator(eod);

  Vue.prototype.$leaflet = window.L;
  Vue.prototype.$eod = eod;
  Vue.prototype.$eodShare = eodShare;
  Vue.prototype.$resolver = new DashboardResolver(eod);
  Vue.prototype.$helper = helper;
  Vue.prototype.$translator = translator;
  Vue.prototype.$isMobile = false;
  Vue.prototype.$trans = (key, autoTranslate) => {
    return translator.getTranslation(key, autoTranslate);
  };
  Vue.prototype.$validator = validator;
  Vue.prototype.$moment = moment;
  Vue.prototype.$keycloak = keycloak;
  Vue.prototype.$websocket = new EODWebSocket(keycloak.token);

  let toaster = new Toaster({
    theme: {
      success: 'var(--v-success-base)',
      danger: 'var(--v-error-base)'
    }
  });

  Vue.prototype.$toaster = toaster;

  router.beforeEach(async (to, from, next) => {
    if(router.app.$children[0]){
      router.app.$children[0].reset();
    }

    if(!checkPermissions(to)){
      console.error('no access', to);
      return next({name:'403'});
    }

    await checkRestoring();
  
    const time_range = localStorage.getItem('eyeondata.routes.'+to.name+'.time_range');
    if(!to.query.time_range && time_range){
      const query = to.query;
      query.time_range = time_range;
      const routeVar = {name:to.name, params:to.params, query:query};
      router.replace(routeVar);
      return;
    }

    next();

  });

  setInterval(() => {
    if(keycloak.isTokenExpired(120)){
      console.error('token expired!')
      keycloak.updateToken(120).then(() => {
        Vue.prototype.$websocket.setToken(keycloak.token);
        Vue.prototype.$eod.loginUser(keycloak.token);
      });
    }
    
  }, 5000);

  const app = new Vue({
    router,
    i18n,
    vuetify,
    render: function (h, b, v) {
      // Render clean template for popup
      if (this._route.name == 'events-popup') {
        return h(CleanApp);
      } else {
        return h(App);
      }
    }
  }).$mount('#app');

  async function checkRestoring() {
    await eod.loadUserOrganization().then(async organization => {
      const restoreRunningSetting = await eod.getOrganizationSetting('backup.restore.running');
      if (restoreRunningSetting && restoreRunningSetting.value == 1) {
        await app.$children[0].setRestoring(true);
      }
    });
  }

  function checkPermissions(to){
    if(to.meta.permissions){
      for (let i = 0; i < to.meta.permissions.length; i++) {
        const permission = to.meta.permissions[i];
        if(eod.getUser().can(permission)){
          return true;
        }
      }
    }else{
      return true;
    }

    return false;
  }
}

