import User from './../../models/user.js';
import moment from 'moment';



const QUERY_AGGREGATIONS = {
    count: {
        key: 'count',
        text: 'Aantal'
    },
    mean: {
        key: 'mean',
        text: 'Gemiddelde'
    },
    sum: {
        key: 'sum',
        text: 'Som'
    },
    last: {
        key: 'last',
        text: 'Laatste'
    },
    first: {
        key: 'first',
        text: 'Eerste'
    },
    max: {
        key: 'max',
        text: 'Hoogste'
    },
    min: {
        key: 'min',
        text: 'Laagste'
    },
    increase: {
        key: 'increase',
        text: 'Increase'
    },
    cumulativeSum: {
        key: 'cumulativeSum',
        text: 'Cumulatieve som',
        requiredGrouping: ['time']
    },
    spread: {
        key: 'spread',
        text: 'Verschil max - min'
    },
}

export const QUERY_FIELD_TYPES = {
    number: {
        key: 'number',
        text: 'Numeriek',
        operators: ['=','<','>','<>'],
        aggregations: Object.values(QUERY_AGGREGATIONS),
        filter:{
            component: () => import('./../../components/eod-text-field.vue')
        },
    },
    module: {
        key: 'module',
        text: 'Object ID',
        operators: ['='],
        filter:{
            component: () => import('./../../components/eod-object-autocomplete.vue')
        },
    },
    treeview: {
        key: 'treeview',
        text: 'Object ID (Treeview)',
        operators: ['='],
        filter:{
            component: () => import('./../../components/eod-objects-treeview-input.vue')
        },
    },
    timestamp: {
        key: 'timestamp',
        text: 'Tijdstip',
        operators: ['=','<','>','<>'],
        formatter(value){
            return moment(value).format('YYYY-MM-DD HH:mm');
        },
        filter:{
            component: () => import('./../../components/eod-date-time-picker.vue')
        },
    },
    text: {
        key: 'text',
        text: 'Tekst',
        operators: ['=', '<>'],
        filter:{
            component: () => import('./../../components/eod-text-field.vue')
        },
    },
    boolean: {
        key: 'boolean',
        text: 'Ja/Nee',
        operators: ['=', '<>'],
        filter:{
            component: () => import('./../../components/eod-checkbox.vue')
        },
    }
}

export const QUERY_FIELD_VISUALIZATIONS = {
    raw: {
        key: 'raw',
        sortable: true,
        defaultSortDesc: false,
        component: () => import('./../../components/graphs2/fields/raw.vue'),
        text: 'Ruwe waarde',
    },
    timestamp: {
        key: 'timestamp',
        sortable: true,
        defaultSortDesc: true,
        component: () => import('./../../components/graphs2/fields/timestamp.vue'),
        text: 'Tijdstip'
    },
    resolved: {
        key: 'resolved',
        component: () => import('./../../components/graphs2/fields/resolved.vue'),
        text: 'Naam',
        sortable: true,
        sortResolved: true,
        defaultSortDesc: false,
    },
    trend: {
        key: 'trend',
        component: () => import('./../../components/graphs2/fields/trend.vue'),
        text: 'Trendgrafiek',
        sortable: false,
        options: [
            {
                key: 'showEvents',
                text: 'Events weergeven',
                type: QUERY_FIELD_TYPES.boolean,
                default: false
            },
            {
                key: 'showAlarmBoundries',
                text: 'Alarm bereik weergeven',
                type: QUERY_FIELD_TYPES.boolean,
                default: false
            }
        ]
    },
}

export const QUERY_REPLACE_TAGS = {
    dynamic_filter: {
        key: 'dynamic_filter',
        value: '[[dynamic_filter:veldNaam]]',
        text: "Vervangt de tag met 'AND veldNaam = filterWaarde'<br>Bijvoorbeeld: [[dynamic_filter:project_id]] voegt ' AND project_id = \"...PROJECT_ID...\"' toe",
    },
    range_start: {
        key: 'range_start',
        value: '[[range_start]]',
        text: "Vervangt de tag met 'van' tijdstip",
    },
    range_stop: {
        key: 'range_stop',
        value: '[[range_stop]]',
        text: "Vervangt de tag met 'tot' tijdstip",
    },
    window: {
        key: 'window',
        value: '[[window]]',
        text: "Vervangt de tag met 'time_bucket('INGESTELD_INTERVAL', time)': Enkel te gebruiken in combinatie met 'GROUP BY time'",
    },
    access: {
        key: 'access',
        value: '[[access:veldNaam]]',
        text: "Vervangt de tag met 'AND veldNaam = ANY(getAccessableProjectsByUserId('GebruikersId'))'",
    },
    current_user_id: {
        key: 'current_user_id',
        value: 'current_user_id',
        text: "Vervangt de tag met het ID van de ingelogde gebruiker",
    },
}

export const QUERY_FIELDS = {
    value: {
        key: 'value',
        text: 'Waarde',
        type: QUERY_FIELD_TYPES.number,
        views: [QUERY_FIELD_VISUALIZATIONS.raw, QUERY_FIELD_VISUALIZATIONS.trend]
    },
    time: {
        key: 'time',
        text: 'Tijd',
        icon: 'mdi-clock-outline',
        type: QUERY_FIELD_TYPES.timestamp,
        views: [QUERY_FIELD_VISUALIZATIONS.timestamp, QUERY_FIELD_VISUALIZATIONS.raw]
    },
    id: {
        key: 'id',
        text: 'ID',
        icon: 'mdi-card-text-outline',
        type: QUERY_FIELD_TYPES.text,
        views: [QUERY_FIELD_VISUALIZATIONS.raw]
    },
    name: {
        key: 'name',
        text: 'Naam',
        icon: 'mdi-card-text-outline',
        type: QUERY_FIELD_TYPES.text,
        views: [QUERY_FIELD_VISUALIZATIONS.raw]
    },
    message: {
        key: 'message',
        text: 'Bericht',
        icon: 'mdi-card-text-outline',
        type: QUERY_FIELD_TYPES.text,
        views: [QUERY_FIELD_VISUALIZATIONS.raw]
    },
    status: {
        key: 'status',
        text: 'Status',
        type: QUERY_FIELD_TYPES.text,
        views: [QUERY_FIELD_VISUALIZATIONS.raw]
    },
    severity: {
        key: 'severity',
        text: 'Niveau',
        type: QUERY_FIELD_TYPES.text,
        views: [QUERY_FIELD_VISUALIZATIONS.raw]
    },
    project_id: {
        key: 'project_id',
        text: 'Project',
        type: QUERY_FIELD_TYPES.module,
        endpoint: 'projects',
        icon: 'mdi-folder-open-outline',
        queryFields:['id','name','description'],
        views: [QUERY_FIELD_VISUALIZATIONS.resolved,
            {
                ...QUERY_FIELD_VISUALIZATIONS.resolved,
                key: 'dashboard-link',
                text: 'Link naar dashboard',
                attrs: {
                    to: {name:'dashboard.project', params:{id: '{{value}}'}}
                },
                options: [
                    {
                        key: 'showDescription',
                        text: 'Omschrijving weergeven',
                        type: QUERY_FIELD_TYPES.boolean,
                        default: false
                    },
                    {
                        key: 'showIcon',
                        text: 'Icoon weergeven',
                        type: QUERY_FIELD_TYPES.boolean,
                        default: false
                    },
                ]
            },
            QUERY_FIELD_VISUALIZATIONS.raw
        ]
    },
    product_id: {
        endpoint: 'products',
        key: 'product_id',
        text: 'Asset',
        icon: 'mdi-cube-outline',
        queryFields:['id','name', 'color'],
        type: QUERY_FIELD_TYPES.treeview,
        views: [QUERY_FIELD_VISUALIZATIONS.resolved,
            {
                ...QUERY_FIELD_VISUALIZATIONS.resolved,
                key: 'dashboard-link',
                text: 'Link naar dashboard',
                attrs: {
                    to: {name:'dashboard.product', params:{id: '{{value}}'}}
                }
            },
            QUERY_FIELD_VISUALIZATIONS.raw
        ],
        filter: {
            componentAttrs: {
                classes: ['COMPANY', 'PROJECT', 'DEVICE','DEVICE_FOLDER'],
                selectable: ['DEVICE']
            }
        }
    },
    device_id: {
        endpoint: 'products',
        key: 'device_id',
        text: 'Asset',
        icon: 'mdi-cube-outline',
        type: QUERY_FIELD_TYPES.treeview,
        views: [QUERY_FIELD_VISUALIZATIONS.resolved,
            {
                ...QUERY_FIELD_VISUALIZATIONS.resolved,
                key: 'dashboard-link',
                text: 'Link naar dashboard',
                attrs: {
                    to: {name:'dashboard.product', params:{id: '{{value}}'}}
                }
            },
            QUERY_FIELD_VISUALIZATIONS.raw
        ],
        filter: {
            componentAttrs: {
                classes: ['COMPANY', 'PROJECT', 'DEVICE','DEVICE_FOLDER'],
                selectable: ['DEVICE']
            }
        }
    },
    product_type_id: {
        endpoint: 'productTypes',
        key: 'product_type_id',
        text: 'Type asset',
        icon: 'mdi-cube-outline',
        type: QUERY_FIELD_TYPES.module,
        views: [QUERY_FIELD_VISUALIZATIONS.resolved, QUERY_FIELD_VISUALIZATIONS.raw],
    },
    owner_id: {
        key: 'owner_id',
        text: 'Eigenaar',
        icon: 'mdi-account-outline',
        type: QUERY_FIELD_TYPES.module,
        endpoint: 'resolveUsers',
        itemClass: User,
        views: [QUERY_FIELD_VISUALIZATIONS.resolved, QUERY_FIELD_VISUALIZATIONS.raw],
    },
    job_type_id: {
        key: 'job_type_id',
        text: 'Jobtype',
        icon: 'mdi-form-select',
        type: QUERY_FIELD_TYPES.module,
        endpoint: 'jobTypes',
        views: [QUERY_FIELD_VISUALIZATIONS.resolved, QUERY_FIELD_VISUALIZATIONS.raw],
    },
    measurement_type_id: {
        key: 'measurement_type_id',
        text: 'Meetwaarde',
        icon:'mdi-waveform',
        type: QUERY_FIELD_TYPES.module,
        endpoint: 'measurementTypes',
        views: [QUERY_FIELD_VISUALIZATIONS.resolved, QUERY_FIELD_VISUALIZATIONS.raw],
    },
    company_id: {
        key: 'company_id',
        text: 'Bedrijf',
        icon:'mdi-office-building-outline',
        type: QUERY_FIELD_TYPES.module,
        endpoint: 'companies',
        views: [QUERY_FIELD_VISUALIZATIONS.resolved, QUERY_FIELD_VISUALIZATIONS.raw],
    },
}

export const QUERY_SOURCES = {
    measurements: {
        table: 'measurements',
        text: 'Meetwaardes',
        fields: [QUERY_FIELDS.value,QUERY_FIELDS.time, QUERY_FIELDS.project_id, QUERY_FIELDS.product_id, QUERY_FIELDS.product_type_id, QUERY_FIELDS.owner_id, QUERY_FIELDS.job_type_id, QUERY_FIELDS.measurement_type_id, QUERY_FIELDS.company_id],
    },
    events: {
        table: 'events',
        text: 'Alarmen',
        fields: [QUERY_FIELDS.value, QUERY_FIELDS.status, QUERY_FIELDS.severity, QUERY_FIELDS.time, QUERY_FIELDS.message, QUERY_FIELDS.project_id, QUERY_FIELDS.product_id, QUERY_FIELDS.owner_id, QUERY_FIELDS.job_type_id, QUERY_FIELDS.measurement_type_id],
    },
    logs: {
        table: 'logs',
        text: 'Logs',
        fields: [QUERY_FIELDS.time, QUERY_FIELDS.message, QUERY_FIELDS.project_id, QUERY_FIELDS.product_id, QUERY_FIELDS.owner_id, QUERY_FIELDS.job_type_id, QUERY_FIELDS.measurement_type_id],
    },
}

export function validateQueryTemplate(template){
    const content = template.content;
    const errors = [];
            
    if(!content.fields || !content.fields[0]){
        errors.push('Gelieve minstens 1 veld te selecteren');
        return errors;
    }

    const fieldsWithAggregation = content.fields.filter(item => item.aggregation);
    for (let i = 0; i < fieldsWithAggregation.length; i++) {
        const field = fieldsWithAggregation[i];
        const aggConfig = QUERY_AGGREGATIONS[field.aggregation];
        if(aggConfig.requiredGrouping){
            for (let j = 0; j < aggConfig.requiredGrouping.length; j++) {
                const groupField = aggConfig.requiredGrouping[j];
                if(!content.group || !content.group.includes(groupField)){
                    errors.push('Veld ' + QUERY_FIELDS[groupField].text + ' moet worden toegevoegd aan de groepering');
                }
            }
        }
    }

    if(content.group && content.group[0]){
        const fieldsWithoutAggregation = content.fields.filter(item => !item.aggregation).map(item => item.key)
        for (let i = 0; i < fieldsWithoutAggregation.length; i++) {
            const field = fieldsWithoutAggregation[i];
            if(!content.group.includes(field)){
                errors.push('Veld ' + QUERY_FIELDS[field].text + ' moet worden toegevoegd aan de groepering');
            }
        }
    } else if(fieldsWithAggregation[0]){
        errors.push('U moet minstens op 1 veld groeperen als je een bewerking wilt uitvoeren op een veld');
    }

    return errors;
}