<template>
    <eod-card :to="isEditing?null:(value.link||null)" :loading="loading" v-if="value" class="w-100 h-100 d-flex flex-column position-relative eod-graph__card" title-class="pb-0 pt-2" v-bind="$attrs" v-on="$listeners" :title="value.title||null" :icon="value.icon?'mdi-'+value.icon:null" :dark="value.textColor == 'dark'" :color="$attrs.color?$attrs.color:(value.bgColor||null)">
        <template v-slot:header-actions v-if="!hideToolbar">
            <template v-if="!isEditing">
            <v-btn icon small v-if="value.description" @click="isInfoDialogVisible = true"><v-icon small>mdi-information-outline</v-icon></v-btn>
            <template v-if="$eod.getUser().can('alarms.read')">
                <v-btn :color="getHighestAlarmColor(alarms)" v-if="alarms[0] && series[0] && series.length == 1 && !alarms[0].event" small icon @click="setAlarm(0)"><v-icon small>mdi-bell-alert-outline</v-icon></v-btn>
                <v-menu v-else open-on-hover offset-y>
                    <template v-slot:activator="{attrs, on}">
                        <v-btn v-if="getHighestAlarmColor(alarms) != 'default'" :color="getHighestAlarmColor(alarms)" x-small fab dark depressed v-on="on" v-bind="attrs"><v-icon small>mdi-bell-alert-outline</v-icon></v-btn>
                        <v-btn v-else small icon v-on="on" v-bind="attrs"><v-icon small>mdi-bell-alert-outline</v-icon></v-btn>
                    </template>
                    <v-card>
                        <v-list dense>
                            <v-list-item :style="getAlarmStyle(alarms[serieIndex].event)" @click="setAlarm(serieIndex)" v-for="(serie, serieIndex) in series" :key="'serie_'+serieIndex">
                                <v-list-item-content>
                                <v-list-item-title>{{ serie.name }}</v-list-item-title>
                                <v-list-item-subtitle :style="getAlarmStyle(alarms[serieIndex].event)" v-if="alarms[serieIndex].event">{{ $moment(alarms[serieIndex].event.time).format('DD/MM HH:mm') }} - {{ alarms[serieIndex].event.message }}</v-list-item-subtitle>
                            </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-menu>

                <eod-dialog v-if="isAlarmDialogVisible && alarmDialogSerie" v-model="isAlarmDialogVisible" title="Alarmen" :subtitle="alarmDialogSerie.name" :width="1600"
                    icon="mdi-bell-alert-outline" iconColor="primary">
                    <template v-slot:headerActions>
                        <v-btn @click="addAlarm" rounded depressed color="secondary"><v-icon left>mdi-plus</v-icon>Toevoegen</v-btn>
                    </template>
                    <eod-alarm-list ref="alarmList" create-on-empty :filters="alarmDialogFilters" compact></eod-alarm-list>
                    <template v-slot:actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="isAlarmDialogVisible = false">Sluiten</v-btn>
                    </template>
                </eod-dialog>

            </template>

            <v-menu offset-y>
                    <template v-slot:activator="{attrs, on}">
                        <v-btn small icon v-bind="attrs" v-on="on"><v-icon small>mdi-dots-vertical</v-icon></v-btn>
                    </template>
                    <eod-card>
                        <v-list dense>
                            <v-list-item @click="$emit('maximize', value)" v-if="!isMaximized">
                                    <v-list-item-action class="mr-4"><v-icon small>mdi-arrow-expand</v-icon></v-list-item-action>
                                    <v-list-item-title>Vergroten</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="$emit('reload', value)">
                                    <v-list-item-action class="mr-4"><v-icon small>mdi-refresh</v-icon></v-list-item-action>
                                    <v-list-item-title>Herladen</v-list-item-title>
                            </v-list-item>
                            <v-menu offset-x v-if="CELL_TYPES[value.typeId].config && CELL_TYPES[value.typeId].config.exportTypes">
                                <template v-slot:activator="{attrs, on}">
                                    <v-list-item v-on="on" v-bind="attrs">
                                        <v-list-item-action class="mr-4"><v-icon small>mdi-database-export</v-icon></v-list-item-action>
                                        <v-list-item-title>Exporteren</v-list-item-title>
                                        <v-list-item-action class="mr-l"><v-icon small>mdi-chevron-right</v-icon></v-list-item-action>
                                    </v-list-item>
                                </template>
                                <v-list dense>
                                    <template v-if="CELL_TYPES[value.typeId].config.exportTypes.map(item => item.key).includes('csv')">
                                        <v-list-item v-if="series.length == 1" @click="$emit('exportData', 'csv', 0)">
                                            <v-list-item-action class="mr-4"><v-icon small>mdi-table</v-icon></v-list-item-action>
                                            <v-list-item-title>CSV</v-list-item-title>
                                        </v-list-item>
                                        <v-menu v-if="series.length > 1" offset-x>
                                            <template v-slot:activator="{attrs, on}">
                                                <v-list-item v-on="on" v-bind="attrs">
                                                    <v-list-item-action class="mr-4"><v-icon small>mdi-table</v-icon></v-list-item-action>
                                                    <v-list-item-title>CSV</v-list-item-title>
                                                    <v-list-item-action class="mr-l"><v-icon small>mdi-chevron-right</v-icon></v-list-item-action>
                                                </v-list-item>
                                            </template>
                                            <v-list dense>
                                                <v-list-item v-for="(serie, serieIndex) in series" :key="serieIndex" @click="$emit('exportData', 'csv', serieIndex)">
                                                    <v-list-item-action class="mr-4"><v-icon small>mdi-table</v-icon></v-list-item-action>
                                                    <v-list-item-title>{{value.series[serieIndex].label}}</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </template>
                                    
                                    <v-list-item v-if="CELL_TYPES[value.typeId].config.exportTypes.map(item => item.key).includes('image')" @click="$emit('exportData', 'image')">
                                        <v-list-item-action class="mr-4"><v-icon small>mdi-image</v-icon></v-list-item-action>
                                        <v-list-item-title>Afbeelding</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-list>
                    </eod-card>
                </v-menu>
                <v-btn small @click="$emit('close', value)" v-if="isMaximized" icon><v-icon small>mdi-close</v-icon></v-btn>
                <eod-dialog v-if="isInfoDialogVisible" v-model="isInfoDialogVisible" title="Info" :width="400" icon="mdi-information-outline" iconColor="primary">
                    <div class="mt-4" v-html="value.description"></div>
                    <template v-slot:actions>
                        <v-spacer></v-spacer>
                        <eod-btn @click="isInfoDialogVisible = false">Sluiten</eod-btn>
                    </template>
                </eod-dialog>
            </template>
            <template v-else>
                <eod-btn @click="$emit('edit', value)" small icon color="grey darken-2"><v-icon small>mdi-pencil-outline</v-icon></eod-btn>
                <eod-btn @click="$emit('delete', value)" color="error" small icon><v-icon small>mdi-trash-can-outline</v-icon></eod-btn>
            </template>
        </template>
        <slot name="default" v-bind:contextmenu="contextmenu" v-bind:mouseup="mouseup"></slot>

        <eod-event-dialog v-if="isEventDialogVisible && (activeEventId || activeEventData)" v-model="isEventDialogVisible" :itemId="activeEventId" :defaultData="activeEventData" @saved="$emit('reload')" @deleted="$emit('reload')"></eod-event-dialog>

        <v-menu v-model="isContextMenuVisible" :position-x="contextMenu.x" :position-y="contextMenu.y" absolute offset-y>
                    <v-list dense>
                        <v-list-item @click="addEvent(contextMenu.serie, contextMenu.data)">
                            <v-list-item-icon><v-icon>mdi-bell-plus-outline</v-icon></v-list-item-icon>
                            <v-list-item-title>Event aanmaken</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="$eod.getUser().can('alarms.create')" @click="setAlarm(contextMenu.serieIndex)">
                            <v-list-item-icon><v-icon>mdi-bell-alert-outline</v-icon></v-list-item-icon>
                            <v-list-item-title>Alarm aanmaken</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>

    </eod-card>
</template>
<script>
import { CELL_TYPES } from './../../models/dashboard';
import Event from './../../models/event.js';
import eodAlarmList from './../eod-alarm-list.vue';
import eodEventDialog from '../eod-event-dialog.vue';

export default {
    name: 'eod-graph',
    components:{
        eodAlarmList,
        eodEventDialog
    },
    props:{
        value: Object,
        isEditing: Boolean,
        isMaximized: Boolean,
        hideToolbar: Boolean,
        loading: Boolean,
        series: Array,
        filters: Array,
        alarms: Array,
    },
    data: () => ({
        isInfoDialogVisible: false,
        CELL_TYPES: CELL_TYPES,
        alarmDialogSerie: null,
        isAlarmDialogVisible: false,
        alarmDialogFilters: {},
        activeEventId: null,
        activeEventData: null,
        isEventDialogVisible: false,
        isContextMenuVisible: false,
        contextMenu: {
            data:null,
            serieIndex:0,
            serie:null,
            x: 0,
            y: 0,
        }
    }),
    methods: {
        mouseup(params, serie){
            this.activeEventData = null;
            this.activeEventId = null;

            this.$nextTick(() => {
                if (params.event.event) {
                    params.event.event.preventDefault();
                } else {
                    params.event.preventDefault();
                }

                if(params.componentType == 'series'){
                    if(params.value && params.value[0]){
                        this.contextMenu.x = params.event.event.pageX;
                        this.contextMenu.y = params.event.event.y;
                        this.contextMenu.serieIndex = params.seriesIndex;
                        this.contextMenu.serie = serie;
                        this.contextMenu.data = params.data;
                        this.isContextMenuVisible = true;
                    }
                } else if (params.componentType == 'markPoint'){
                    if(params.data && params.data.eventId){
                        
                        // Open event dialog
                        this.activeEventId = params.data.eventId;
                        this.isEventDialogVisible = true;
                    }
                }
            });

            
        },
        contextmenu(params){
            if (params.event) {
                if (params.event.event) {
                    params.event.event.preventDefault();
                } else {
                    params.event.preventDefault();
                }
            }
        },
        addEvent(serie, data){
            this.activeEventData = {
                time: data.value[0],
                duration: 100,
                facility: 'user',
                severity: 'info',
                userStatus: 'unassigned',
                type: 'new',
                responsibleUser: this.$eod.getUser(),
                value: ''+data.value[1]
            };
            if(serie.serieFilters){
                for (let i = 0; i < serie.serieFilters.length; i++) {
                    const filter = serie.serieFilters[i];
                    if(filter.column == 'projectId'){
                        this.activeEventData.project = this.$resolver.get('projects', filter.value);
                        this.activeEventData.company = this.activeEventData.project.company;
                    } else if(filter.column == 'measurementTypeId'){
                        this.activeEventData.measurementType = this.$resolver.get('measurementTypes', filter.value);
                    } else if(filter.column == 'productId'){
                        this.activeEventData.product = this.$resolver.get('products', filter.value);
                    } else if(filter.column == 'ownerId'){
                        this.activeEventData.owner = this.$resolver.get('users', filter.value);
                    } else if(filter.column == 'jobTypeId'){
                        this.activeEventData.jobTypeId = filter.value;
                    }
                }
            }

            this.isEventDialogVisible = true;
        },
        async setAlarm(serieIndex) {

            const related = [];

            // Add project from filter
            for (let i = 0; i < this.filters.length; i++) {
                const filter = this.filters[i];
                if (filter.key == 'project_id' && filter.value) {
                    related.push({ array: [filter.value], column: 'projectId', relation: 'projects_templates' });
                }
            }

            // Add owner, product and jobType from dataset
            const serie = this.series[serieIndex];
            if(serie[0]){
                const firstDataPoint = serie[0];

                if(firstDataPoint.owner_id){
                    related.push({ array: [firstDataPoint.owner_id], column: 'userId', relation: 'users_templates' });
                }

                if(firstDataPoint.product_id){
                    related.push({ array: [firstDataPoint.product_id], column: 'productId', relation: 'products_templates' });
                }

                if(firstDataPoint.job_type_id){
                    related.push({ array: [firstDataPoint.job_type_id], column: 'jobTypeId', relation: 'jobTypes_templates' });
                }
                
            }

            const alarm = this.alarms[serieIndex];
            if(alarm.filters){
                for (let i = 0; i < alarm.filters.length; i++) {
                    const filter = alarm.filters[i];
                    if(filter.field.key == 'measurement_type_id'){
                        related.push({ array: [filter.value], column: 'measurementTypeId', relation: 'measurementTypes_templates' });
                    }
                }
            }

            if (related.length > 0) {
                this.alarmDialogFilters = {
                    whereInRelated: related,
                };
            }else{
                this.alarmDialogFilters = {};
            }

            this.alarmDialogSerie = serie;
            this.isAlarmDialogVisible = true;
        },
        addAlarm(){
            if(this.$refs.alarmList){
                this.$refs.alarmList.editTemplate(null);
            }
        },

        getHighestAlarmColor(){
            let highestAlarm = null;
            for (let i = 0; i < this.alarms.length; i++) {
                const alarm = this.alarms[i].event;
                if(alarm && alarm.isMasked){
                    alarm.severityCode = 0;
                }
                if(alarm && (!highestAlarm || highestAlarm.severityCode > alarm.severityCode)){
                    highestAlarm = alarm;
                }
            }

            if(!highestAlarm){
                return 'default';
            }

            return this.getAlarmColor(highestAlarm);
        },
        getAlarmColor(event){

            if(event.isMasked){
                return '#9b2fae';
            }

            return Event.getSeverity(event.severity).color;
        },
        getAlarmStyle(event){
            if(!event){
                return '';
            }

            return 'background-color:'+this.getAlarmColor(event)+';color:white;';
        },
    }
}
</script>