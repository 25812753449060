import Template from './template';

export default class Query extends Template {
    static SOURCES = [
        {
            endpoint: 'measurements',
            title: 'Meetwaarden',
            isVisible(template){
                return true;
            }
        },
        {
            endpoint: 'events',
            title: 'Events',
            isVisible(template){
                if(template.content.graphType == 'sankey'){
                    return false;
                }

                return true;
            }
        },
        {
            endpoint: 'projects',
            title: 'Projecten',
            isVisible(template){
                if(template.content.graphType == 'sankey'){
                    return false;
                }

                return true;
            }
        },
        {
            endpoint: 'products',
            title: 'Assets',
            isVisible(template){
                if(template.content.graphType == 'sankey'){
                    return false;
                }

                return true;
            }
        }
    ];
    static OPTIONS = {
        measurements: {
            default: {
                source: 'measurements',
                filters: [],
                measurementTypes:[
                    {
                        aggregation: 'mean',
                        object: null
                    }
                ],
                group: {
                    key: 'time',
                    title: 'Tijd',
                    value: '1h'
                }
            },
            groupings: [
                {
                    key: null,
                    title: 'Geen'
                },{
                key: 'time',
                title: 'Tijd'
            },
            {
                key: 'project_id',
                title: 'Project',
                endpoint: 'resolveProjects'
            }
                /*{
                    key: 'task_id',
                    title: 'Taak',
                    endpoint: 'resolveTasks'
                },
                ,*/
            ],
            aggregations: [
            {
                key: 'count',
                title: 'Aantal'
            },{
                key: 'mean',
                title: 'Gemiddelde'
            },
            {
                key: 'sum',
                title: 'Som'
            },
            {
                key: 'last',
                title: 'Laatste'
            },
            {
                key: 'first',
                title: 'Eerste'
            },
            {
                key: 'max',
                title: 'Hoogste'
            },
            {
                key: 'min',
                title: 'Laagste'
            },
            {
                key: 'increase',
                title: 'Increase'
            },
            {
                key: 'cumulativeSum',
                title: 'Cumulatieve som'
            },
            {
                key: 'spread',
                title: 'Verschil max - min'
            },
            ],
            operators: [{
                key: '==',
                title: '='
            },
            {
                key: '>',
                title: '>'
            },
            {
                key: '<',
                title: '<'
            },
            ],
            filterOptions: [{
                endpoint: 'tasks',
                key: 'task_id',
                title: 'Taak'
            },
            {
                endpoint: 'taskTypes',
                key: 'task_type_id',
                title: 'Taaktype'
            },
            {
                endpoint: 'jobTypes',
                key: 'job_type_id',
                title: 'Jobtype'
            },
            {
                endpoint: 'resolveProjects',
                key: 'project_id',
                title: 'Project'
            },
            {
                endpoint: 'services',
                key: 'service_id',
                title: 'Dienst'
            },
            {
                endpoint: 'servicetypes',
                key: 'service_type_id',
                title: 'Diensttype'
            },
            {
                endpoint: 'companies',
                key: 'company_id',
                title: 'Bedrijf'
            },
            /*{
                endpoint: 'measurementTypes',
                key: 'measurementType',
                title: 'Meetwaarde'
            },*/
            {
                endpoint: 'resolveUsers',
                key: 'owner_id',
                title: 'Eigenaar',
                fields: ['id', 'firstName', 'lastName', 'assignedRealmRoles{name}'],
            },
            {
                endpoint: 'resolveProducts',
                key: 'product_id',
                title: 'Asset'
            },
            ],
        },
        projects: {
            default: {
                source: 'projects',
                filters: [],
                measurementTypes:[
                    {
                        aggregation: 'mean',
                        object: null
                    }
                ],
                group: {
                    key: 'project_id'
                },
            },
            groupings: [{
                key: 'project_id',
                title: 'Project'
            }
            ],
            aggregations: [{
                key: 'count',
                title: 'Aantal'
            },{
                key: 'mean',
                title: 'Gemiddelde'
            },
            {
                key: 'sum',
                title: 'Som'
            },
            {
                key: 'last',
                title: 'Laatste'
            },
            {
                key: 'first',
                title: 'Eerste'
            },
            {
                key: 'max',
                title: 'Hoogste'
            },
            {
                key: 'min',
                title: 'Laagste'
            },
            {
                key: 'increase',
                title: 'Increase'
            },
            {
                key: 'spread',
                title: 'Verschil max - min'
            },
            ],
            operators: [{
                key: '==',
                title: '='
            },
            {
                key: '>',
                title: '>'
            },
            {
                key: '<',
                title: '<'
            },
            ],
            filterOptions: [
            {
                endpoint: 'resolveProjects',
                key: 'project_id',
                title: 'Project'
            },
            {
                endpoint: 'companies',
                key: 'company_id',
                title: 'Bedrijf'
            },
            ],
        },
        products: {
            default: {
                source: 'products',
                filters: [],
                measurementTypes:[
                    {
                        aggregation: 'mean',
                        object: null
                    }
                ],
                group: {
                    key: 'product_id'
                },
            },
            groupings: [{
                key: 'product_id',
                title: 'Product'
            }
            ],
            aggregations: [{
                key: 'count',
                title: 'Aantal'
            },{
                key: 'mean',
                title: 'Gemiddelde'
            },
            {
                key: 'sum',
                title: 'Som'
            },
            {
                key: 'last',
                title: 'Laatste'
            },
            {
                key: 'first',
                title: 'Eerste'
            },
            {
                key: 'max',
                title: 'Hoogste'
            },
            {
                key: 'min',
                title: 'Laagste'
            },
            {
                key: 'increase',
                title: 'Increase'
            },
            {
                key: 'spread',
                title: 'Verschil max - min'
            },
            ],
            operators: [{
                key: '==',
                title: '='
            },
            {
                key: '>',
                title: '>'
            },
            {
                key: '<',
                title: '<'
            },
            ],
            filterOptions: [
            {
                endpoint: 'resolveProducts',
                key: 'product_id',
                title: 'Product'
            },
            {
                endpoint: 'productTypes',
                key: 'product_type_id',
                title: 'Type product'
            },
            ],
        },
        events: {
            default: {
                source: 'events',
                filters: [],
                group: {
                    key: null
                },
                measurementTypes:[
                    {
                        aggregation: 'count',
                        object: { id: 'duration', name: 'Duurtijd' }
                    }
                ],
            },
            groupings: [
                {
                    key: null,
                    title: 'Geen'
                }, {
                    key: 'severity',
                    title: 'Ernst'
                },{
                    key: 'time',
                    title: 'Tijd'
                }],
            aggregation_fields: [
                { id: 'duration', name: 'Duurtijd' },
                { id: 'severity_code', name: 'Ernstcode' },
            ],
            aggregations: [
                {
                    key: 'count',
                    title: 'Aantal'
                }, {
                    key: 'mean',
                    title: 'Gemiddelde'
                },
                {
                    key: 'sum',
                    title: 'Som'
                },
                {
                    key: 'last',
                    title: 'Laatste'
                },
                {
                    key: 'first',
                    title: 'Eerste'
                },
                {
                    key: 'max',
                    title: 'Hoogste'
                },
                {
                    key: 'min',
                    title: 'Laagste'
                },
                {
                    key: 'increase',
                    title: 'Increase'
                },
                {
                    key: 'cumulativeSum',
                    title: 'Cumulatieve som'
                },
                {
                    key: 'spread',
                    title: 'Verschil max - min'
                },
            ],
            operators: [{
                key: '==',
                title: '='
            },
            {
                key: '>',
                title: '>'
            },
            {
                key: '<',
                title: '<'
            },
            ],
            filterOptions: [
                {
                    endpoint: 'resolveProjects',
                    key: 'project_id',
                    title: 'Project'
                },
                {
                    endpoint: 'services',
                    key: 'service_id',
                    title: 'Dienst'
                },
                {
                    endpoint: 'companies',
                    key: 'company_id',
                    title: 'Bedrijf'
                },
                {
                    endpoint: 'measurementTypes',
                    key: 'measurement_type_id',
                    title: 'Meetwaarde'
                },
                {
                    endpoint: 'resolveUsers',
                    key: 'owner_id',
                    title: 'Eigenaar'
                },
                {
                    endpoint: 'resolveProducts',
                    key: 'product_id',
                    title: 'Asset'
                },
            ],
        }
    }
}