<template>
    <div class="px-2">
<grid-layout v-if="value" :layout.sync="visibleCells"  @layout-updated="layoutUpdate" :col-num="12" :row-height="30"
    :is-draggable="isEditing" :is-resizable="isEditing" :is-mirrored="false" :vertical-compact="true" is-bounded
    :margin="[15, 15]" use-css-transforms :responsive="!isEditing" ref="gridlayout">
    <grid-item v-for="(item, index) in visibleCells" :x="item.x" :y="item.y" :w="item.w"
        :h="item.h" :i="item.i"
        :class="maximized==item.i?'card-maximized':''"
        :style="(item.active ? 'z-index:1;' : '')+(isEditing?'touch-action:none;':'')"
        :key="item.i" :ref="'item__' + item.i">
        <component v-if="item.typeId" :item="item"
            @createTile="createTile"
            @delete="deleteTile"
            @edit="editTile"
            @maximize="val => maximized=val.i"
            @close="maximized=null"
            @graphLoaded="graphLoaded"
            @zoom="zoom"
            :dashboard="value"
            :variables="variables"
            :ref="item.i" :index="index" :from="timeFilter.from"
            :until="timeFilter.until" :isEditing="isEditing" :filters="filters"
            :value="item" :template="value" :activeEvents="activeEvents"
            :is-maximized="maximized==item.i"
            :is="CELL_TYPES[item.typeId].viewComponent"></component>
    </grid-item>
</grid-layout>
<eod-create-tile-dialog v-if="isNewTileDialogVisible" v-model="isNewTileDialogVisible" :configVariables="configVariables" :variables="variables" :timeFilter="timeFilter" :filters="filters" :default-tile="newTile" @save="saveTile"></eod-create-tile-dialog>
<div :style="maximized ? 'opacity:1;visibility:visible;' : ''" id="backmodal" @click="maximized=null"></div>
</div>
</template>
<style lang="scss" scoped>
.card-maximized{
    z-index:150;
    position:fixed !important;
    width:calc(100vw - 100px) !important;
    height:calc(100vh - 100px) !important;
    transform: none !important;
    left:50px;
    top:50px;
}
.vue-grid-layout {
    min-height: 200px;
    transition: none;
}

.vue-grid-item {
    transition: none;
}

#backmodal {
    opacity: 0;
    visibility: hidden;
    transition: .5s all;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .6);
    z-index: 100;
}
</style>
<script>
import VueGridLayout from 'vue-grid-layout';
import {CELL_TYPES} from '../models/dashboard.js';
import eodCreateTileDialog from './graphs2/dialogs/eod-create-tile-dialog.vue';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import * as echarts from 'echarts';
import 'echarts-liquidfill';

export default {
    name: 'eod-dashboard',
    components: {
        GridLayout: VueGridLayout.GridLayout,
        GridItem: VueGridLayout.GridItem,
        eodCreateTileDialog
    },
    props: {
        value: Object,
        isEditing: {
            type: Boolean,
            default: false
        },
        timeFilter: {
            type: Object,
            default: null
        },
        filters: {
            type: Array,
            default: null
        },
        variables: {
            type: Object,
            default: null
        },
        configVariables: {
            type: Object,
            default: null
        },
        activeEvents: {
            type: Array,
            default: null
        }
    },
    data: () => ({
        CELL_TYPES: CELL_TYPES,
        visibleCells: [],
        newTile: null,
        isNewTileDialogVisible: false,
        maximized:null
    }),
    created(){
        this.loadVisibleCells();
    },
    methods:{
        graphLoaded(id, graph){
            echarts.connect(graph.chart.group);
        },
        zoom(params){
        },
        reload(){
            if(this.visibleCells){
                for (let i = 0; i < this.visibleCells.length; i++) {
                    const cell = this.visibleCells[i];
                    if(this.$refs[cell.i]){
                        if(this.$refs[cell.i][0].reload){
                            this.$refs[cell.i][0].reload();
                        } else {
                            console.error('No reload function for ', cell);
                        }
                        
                    }
                }
            }
        },
        editTile(tile){
            this.newTile = tile;
            this.isNewTileDialogVisible = true;
        },
        deleteTile(tile){
            const val = this.value;
            for (let i = 0; i < val.content.cells.length; i++) {
                if (val.content.cells[i].i == tile.i) {
                    val.content.cells.splice(i, 1);
                    break;
                }
            }

            this.$emit('input', val);
            this.$nextTick(() => {
                this.loadVisibleCells();
            })
        },
        createTile(tile){
            this.newTile = tile;
            this.isNewTileDialogVisible = true;
        },
        saveTile(tile){

            const val = _.cloneDeep(this.value);
            
            if(!val.content.cells){
                val.content.cells = [];
            }

            let updated = null;
            if(!tile.i){
                tile.i = uuidv4();
                val.content.cells.push(_.cloneDeep(tile));
            } else {
                for (let i = 0; i < val.content.cells.length; i++) {
                    const cell = val.content.cells[i];
                    if(cell.i == tile.i){
                        val.content.cells[i] = tile;
                        updated = tile.i;
                        break;
                    }
                }
            }

            this.$emit('input', val);
            this.isNewTileDialogVisible = false;
            this.$nextTick(() => {
                this.loadVisibleCells();

                this.$nextTick(() => {
                    if(updated != null){
                        if(this.$refs[updated]){
                            
                            this.$refs[updated][0].reload();
                        }
                    }
                });
            });
        },
        loadVisibleCells(){
            this.visibleCells = [];

            if(this.value && this.value.content.cells){
                for (let i = 0; i < this.value.content.cells.length; i++) {
                    const cell = this.value.content.cells[i];
                    if(!(cell.hiddenRoles && cell.hiddenRoles.includes(this.$eod.getUser().getRole().name))){
                        this.visibleCells.push({
                            ...cell,
                            index: i,
                        });
                    }
                }
            }
        },
        layoutUpdate(cells){
            const val = this.value;
            for (let i = 0; i < cells.length; i++) {
                const cell = cells[i];
                for (let j = 0; j < val.content.cells.length; j++) {
                    const existingCell = val.content.cells[j];
                    if(existingCell.i == cell.i){
                        val.content.cells[j] = cell;
                        break;
                    }
                }
            }

            this.$emit('input', val);
        },
    }
}
</script>