<template>
    <div>
    <v-row class="mb-0 mt-0">
        <v-col cols="auto">
            <v-chip color="grey lighten-3" label>Vaste filters</v-chip>
        </v-col>
        <v-col>
            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" color="grey"
                        icon><v-icon>mdi-plus-circle-outline</v-icon></v-btn>
                </template>
                <v-list>
                    <v-list-item
                        v-for="filter in fields"
                        :key="filter.key" @click="addFilter(filter)">
                        <v-list-item-title>{{ filter.text }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-col>
                                        </v-row>
                                        <v-row class="mb-0 mt-0" v-for="(filter, fieldName) in groupedFilters()"
        :key="'filter_' + fieldName">
        <v-col cols="auto">
            <v-chip color="primary">{{
                filter.field.text }}</v-chip>
        </v-col>
        <v-col>
            <v-row v-for="(filterVal, valIndex) in filter.values" :key="valIndex">
            <v-col class="d-flex align-items-center" cols="auto">
                <template v-if="value[filterVal.index] && filter.field.type.operators">
                    <v-chip v-if="filter.field.type.operators.length <=1">{{ value[filterVal.index].operator }}</v-chip>
                    <eod-autocomplete
                    style="width:120px;"
                        v-else
                        :value="value[filterVal.index].operator"
                        :items="filter.field.type.operators"
                        @input="val => setValue(filterVal.index, 'operator', val)"></eod-autocomplete>
                </template>
            </v-col>
            <v-col>
                <div class="d-flex align-items-center">
                    <component :is="filter.field.type.filter.component" :value="value[filterVal.index].value" :endpoint="filter.field.endpoint" :variables="variables?variables[filter.field.endpoint]:null" :fields="filter.field.fields" v-bind="(filter.field.filter && filter.field.filter.componentAttrs)?filter.field.filter.componentAttrs:null" @input="val => setValue(filterVal.index, 'value', val, true)"></component>

                        <v-btn @click="removeFilter(filterVal.index)" icon
                        color="error"><v-icon>mdi-trash-can-outline</v-icon></v-btn>
                        <v-btn :disabled="valIndex < filter.values.length-1" @click="addFilter(filter.field)" class="ml-2" small depressed outlined><v-icon left small>mdi-plus</v-icon>Of</v-btn>
                </div>
            </v-col>
        </v-row>
        </v-col>
        
    </v-row>
</div>
</template>
<script>
import {QUERY_FIELDS} from './../plugins/queryeditor/config.js';

export default {
    props:{
        value: Array,
        type: {
            type: String,
            default: ''
        },
        source: {
            type: String,
            default: 'measurements'
        },
        variables: {
            type: Object,
            default: null
        },
        fields: {
            type: Array,
        }
    },
    methods:{
        groupedFilters(){
            const filters = {};
            for (let i = 0; i < this.value.length; i++) {
                const filter = this.value[i];
                if(!filters[filter.field.key]){
                    filters[filter.field.key] = {
                        field: QUERY_FIELDS[filter.field.key]||null,
                        values: []
                    };
                }

                filters[filter.field.key].values.push({
                    value: filter.value,
                    operator: filter.operator,
                    index: i
                });
            }

            return filters;
        },
        addFilter(filter) {
            const val = this.value;
            val.push({
                field: {
                    key: filter.key
                },
                operator: filter.type.operators[0],
                value: null
            });

            this.$emit('input', val);
            this.$nextTick(() => {
                this.$forceUpdate();
            });
            
        },
        setValue(index, fieldname, value, update){
            const val = this.value;
            val[index][fieldname] = value;
            this.$emit('input', val);

            if(update){
                this.$emit('update');
            }
        },
        removeFilter(index) {
            const val = this.value;
            val.splice(index, 1);
            this.$emit('input', val);
            this.$emit('update');
            this.$nextTick(() => {
                this.$forceUpdate();
            });
        }
    }
}
</script>