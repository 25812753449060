<template>
    <v-row>
        <v-col cols="12" md="6">
            <eod-card title="Data">
                <v-card-text>
                    <v-row class="mb-0">
                        <v-col cols="auto">
                            <v-chip color="grey lighten-3" label>Naam*</v-chip>
                        </v-col>
                        <v-col>
                            <eod-text-field hide-details :rules="[val => $validator.required(val, 'Naam')]" :value="value.name" @input="val => updateProperty('name', val)"></eod-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="my-0">
                        <v-col cols="auto">
                            <v-chip color="grey lighten-3" label>Query*</v-chip>
                        </v-col>
                        <v-col>
                            <eod-object-autocomplete :rules="[val => $validator.required(val, 'Query')]" :value="value.content.source" @input="updateSource" :defaultFilter="{whereIn:[{column:'type',array:['QUERY']}]}" endpoint="templates"></eod-object-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row class="my-0">
                        <v-col cols="auto">
                            <v-chip color="grey lighten-3" label>Van</v-chip>
                        </v-col>
                        <v-col>
                            <eod-date-time-picker :value="value.content.from" @input="val => updateContent('from', val)"></eod-date-time-picker>
                        </v-col>
                    </v-row>
                    <v-row class="my-0" v-if="!value.content.periodicity">
                        <v-col cols="auto">
                            <v-chip color="grey lighten-3" label>Tot</v-chip>
                        </v-col>
                        <v-col>
                            <eod-date-time-picker :value="value.content.until" @input="val => updateContent('until', val)"></eod-date-time-picker>
                        </v-col>
                    </v-row>
            </v-card-text>
            </eod-card>
        </v-col>
        <v-col cols="12" md="6">
            <eod-card title="Export">
                <v-card-text>
                    <v-row class="my-0">
                        <v-col cols="auto">
                            <v-chip color="grey lighten-3" label>Herhaling</v-chip>
                        </v-col>
                        <v-col>
                            <eod-autocomplete :value="value.content.periodicity" @input="updatePeriodicity" :items="Export.PERIODICIY"></eod-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row class="my-0">
                        <v-col cols="auto">
                            <v-chip color="grey lighten-3" label>Locatie</v-chip>
                        </v-col>
                        <v-col>
                            <eod-autocomplete :value="value.content.location" @input="val => updateContent('location', val)" :items="exportLocations"></eod-autocomplete>
                            <v-row class="mt-0" v-if="value.content.location">
                                <v-col cols="auto"><v-chip color="grey lighten-3" label>Map</v-chip></v-col>
                                <v-col><eod-text-field placeholder="/" :value="value.content.path" @input="val => updateContent('path', val)"></eod-text-field></v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row class="my-0">
                        <v-col cols="auto">
                            <v-chip color="grey lighten-3" label>Formaat</v-chip>
                        </v-col>
                        <v-col>
                            <eod-autocomplete :value="value.content.format" @input="val => updateContent('format', val)" :items="Export.FORMATS"></eod-autocomplete>
                        </v-col>
                    </v-row>
                </v-card-text>
            </eod-card>
        </v-col>
        <v-col cols="12">
            <eod-card title="Voorbeeld">
                <template v-slot:header-actions>
                    <v-btn :disabled="template == null" @click="executeQuery" depressed rounded color="secondary"><v-icon left>mdi-lightning-bolt</v-icon>Voorbeeld laden</v-btn>
                </template>
                <eod-graph-filters ref="filters" @change="filterChanged" v-model="template"></eod-graph-filters>
                <eod-graph-table ref="table" :from="$moment(value.content.from)" :until="$moment(value.content.until)" :value="executeTemplate" hide-toolbar flat></eod-graph-table>
            </eod-card>
        </v-col>
    </v-row>
</template>
<script>
    import eodDateTimePicker from './eod-date-time-picker.vue';
    import Export from './../models/export';
    import ExternalStorage from '../models/externalStorage';
    import eodObjectAutocomplete from './eod-object-autocomplete.vue';
    import queryPreviewMixin from './graphs2/mixins/query-preview.js';
    import eodGraphTable from './graphs2/eod-graph-table.vue';
    import eodGraphFilters from './graphs/eod-graph-filters.vue';

    export default {
        name: 'eod-export-detail',
        components: {
            eodDateTimePicker,
            eodObjectAutocomplete,
            eodGraphTable,
            eodGraphFilters
        },
        props:{
            value: Object
        },
        mixins:[queryPreviewMixin],
        data: () => ({
            Export: Export,
            exportLocations:[
                {
                    value:null,
                    text: 'Interne opslag (Eye on Data)'
                }
            ],
            template: null,
            executeTemplate: null,
        }),
        created(){
            this.loadQueryTemplate();
            this.loadExternalStorageLocations();
        },
        methods: {
            executeQuery(){
                this.executeTemplate = {
                    graph:{
                        fields:this.template.content.fields?this.template.content.fields.map(item => ({...item, ...{fieldKey:item.key, key: item.aggregation?item.aggregation+'_'+item.key:item.key}})):[]
                    },
                    series:[{query:this.template}]
                };

                this.$nextTick(() => {
                    if(this.$refs.table){
                        this.$refs.table.reload();
                    }
                });
            },
            updateSource(val){

                this.template = null;
                this.updateContent('source', val);

                this.loadQueryTemplate();
            },
            loadQueryTemplate(){
                if(this.value.content.source){
                    this.$eod.getById('template', this.value.content.source, ['id','name','content']).then(response => {
                        if(response.data.data && response.data.data.template){
                            this.template = response.data.data.template;
                        }
                    });
                }
            },
            updatePeriodicity(val){
                this.updateContent('periodicity', val);                
                if(val){
                    const tmpl = this.value;
                    tmpl.content.until = null;
                    this.$emit('input', tmpl);
                }
            },
            updateContent(propertyName, value){
                const val = this.value;
                val.content[propertyName] = value;
                this.$emit('input', val);
            },
            updateProperty(propertyName, value){
                const val = this.value;
                val[propertyName] = value;
                this.$emit('input', val);
            },
            loadExternalStorageLocations(){
                this.$eod.get('templates',['id','name', 'content'], {
                    where:[
                        {
                            column: 'type',
                            operator: '=',
                            value: 'EXTERNAL_STORAGE'
                        }
                    ]
                }).then(response => {
                    if(response && response.data && response.data.data && response.data.data.templates){
                        for (let i = 0; i < response.data.data.templates.edges.length; i++) {
                            const template = response.data.data.templates.edges[i];
                            this.exportLocations.push({
                                value: template.id,
                                text: template.name + ' ('+ExternalStorage.getType(template.content.type).text+')'
                            })
                        }
                    }
                })
            }
        }
    }
</script>