<template>
    <div id="response_form">
        <v-form ref="form">
        <v-overlay :value="loading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <div v-if="step && !loading">
            <eod-card class="mb-4" v-if="template.content.variants && template.content.variants.length > 0">
                <v-card-text>
                    <v-autocomplete :disabled="readonlyVariant" :items="template.content.variants" dense outlined
                        label="Variant" hide-details v-model="template.content.variant"
                        @change="updateTemplate"></v-autocomplete>
                </v-card-text>
            </eod-card>
            <eod-card class="mb-4" v-if="variantsCheck && variantVariables && variantVariables.length > 0" title="Assets">
                <v-list dense>
                    <v-list-item v-for="(variable, variableIndex) in variantVariables" :key="variable.value">
                        <v-list-item-content>
                            <v-list-item-title>{{ variable.text }}</v-list-item-title>
                            <v-list-item-subtitle v-if="variable.product">{{
                                variable.product.name
                            }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action v-if="variable.product && !readonly">
                            
                            <v-btn @click="showActionsDialog(variable)" small icon><v-icon small>mdi-pencil</v-icon></v-btn>
                            <!-- <v-btn v-if="!variable.edit"
                                @click="template.content.variables[variable.index].edit = true; $forceUpdate()"
                                icon><v-icon>mdi-pencil</v-icon></v-btn>
                            <v-btn v-else icon
                                @click="template.content.variables[variable.index].edit = false; $forceUpdate()"><v-icon>mdi-check</v-icon></v-btn> -->
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
                <eod-action-dialog v-model="isActionsDialogVisible" v-if="activeVariable" :title="activeVariable.text" :items="[
                    {
                        icon:'mdi-swap-horizontal',
                        name:'Andere asset selecteren',
                        action: changeAsset
                    },
                    {
                        icon:'mdi-pencil-outline',
                        name:'Asset bekijken en bewerken',
                        action: editAsset
                    },
                    {
                        icon:'mdi-plus',
                        name:'Nieuwe asset aanmaken',
                        action: createAsset
                    }
                ]">
                            </eod-action-dialog>
            </eod-card>
            <template v-if="variantsCheck && variablesCheck && !loading">
                <v-subheader v-if="!hidePage">{{ step.name }}</v-subheader>
                <div class="mb-4" v-if="step.description && step.description != ''" v-html="step.description"></div>
                <div class="stepper-content">
                    <eod-card class="mb-4" outlined title="Artikelen" v-if="value.job.articles">
                        <v-card-text>
                            <eod-card outlined  v-for="(article, articleIndex) in value.job.articles" :key="articleIndex" class="mb-2">
                            <template v-slot:header>
                                <div class="primary-10">
                                    <v-card-title class="text-body-2 py-2 flex-nowrap position-relative">
                                            <span v-if="article">
                                                
                                                {{article.name}}
                                            </span>
                                            <v-btn class="position-absolute p-0" style="right:-12px;top:-12px;width:24px;height:24px;min-width:auto;border-radius: 50%;" elevation="2" color="error" @click="showDeleteArticleDialog(articleIndex)"><v-icon small>mdi-trash-can-outline</v-icon></v-btn>
                                    </v-card-title>
                                </div>
                                </template>
                            <v-card-text>
                                <eod-article-stock-movement :value="article" @input="val => updateCellArticleStockMovement(articleIndex, val)" :readonly="readonly"></eod-article-stock-movement>
                        </v-card-text>
                        </eod-card>
                        <v-btn rounded depressed color="primary" :block="$isMobile" @click="isAddArticleDialogVisible = true"><v-icon small left>mdi-plus</v-icon>Toevoegen</v-btn>
                        </v-card-text>
                        <eod-delete-dialog v-if="deleteArticleIndex != null" v-model="isDeleteArticleDialogVisible" :item="value.job.articles[deleteArticleIndex]" @delete="deleteArticleAction"></eod-delete-dialog>
                        <eod-dialog width="1200" v-if="isAddArticleDialogVisible" :inset="false" hide-ok v-model="isAddArticleDialogVisible" title="Artikel toevoegen" icon="mdi-pencil-outline" iconColor="primary">
                            <div class="py-4 px-4">
                                <eod-text-field label="Zoeken" @input="updateArticleSearch"></eod-text-field>
                            </div>
                            <div class="pb-4">
                            <eod-objects-treeview readonly hide-extra :search="$helper.filtersToVariables(articleSearchFilters)" @selected="addArticleAction" :selectable="['ARTICLE']" :classes="['ARTICLE_TYPE', 'ARTICLE', 'ARTICLE_FOLDER']" class-name="ARTICLE" object-name="Article"></eod-objects-treeview>
                        </div>
                        </eod-dialog>
                    </eod-card>
                    <v-lazy class="mb-4" v-for="(cell, cellIndex) in variantCells" :options="{
                        threshold: .5
                    }" min-height="100" transition="fade-transition" :key="cellIndex">
                        <div class="text-h6 mb-2" v-if="cell.typeId == 'title'" v-html="getTranslation(cell.name)">
                        </div>
                        <div class="text-subtitle-2 mb-2" v-if="cell.typeId == 'subtitle'"
                            v-html="getTranslation(cell.name)"></div>
                        <div class="text-body-2 mb-2" v-if="cell.typeId == 'text' && !cell.settings.isCollapsable"
                            v-html="getTranslation(cell.name)"></div>
                        <v-expansion-panels class="mb-2" v-if="cell.typeId == 'text' && cell.settings.isCollapsable">
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    {{ getTranslation(cell.settings.collapsableTitle) }}
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <div v-html="getTranslation(cell.name)"></div>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        <eod-card v-if="cell.typeId == 'measurementType'" style="overflow:hidden;">
                            <template v-slot:header>
                                <div class="primary-10">
                                <v-card-title class="text-body-1 py-2">
                                    
                <div class="d-flex flex-column">
                    <div v-if="cell.settings.showTitle && getMeasurementType(cell.settings.measurementTypeId)">
                        <v-icon left small v-if="getMeasurementType(cell.settings.measurementTypeId).icon">mdi-{{ getMeasurementType(cell.settings.measurementTypeId).icon }}</v-icon>
                        {{getMeasurementType(cell.settings.measurementTypeId).name}}<em v-if="getMeasurementType(cell.settings.measurementTypeId).formConfig.required">*</em></div>
                    <div v-if="productsByCell[cell.id]" class="text-caption"><v-icon small left>mdi-package-variant</v-icon>{{ productsByCell[cell.id].name }}</div>
                                </div>
                            <v-spacer></v-spacer>
                            <template v-if="!$isMobile">
                            <v-btn color="error" icon v-if="!readonly" @click="deleteCell(cell)"><v-icon>mdi-trash-can-outline</v-icon></v-btn>
                            
                        </template>
                            </v-card-title>
                            <v-card-subtitle
                                v-if="cell.settings.showDescription && getMeasurementType(cell.settings.measurementTypeId) && getMeasurementType(cell.settings.measurementTypeId).description">{{
                                    getMeasurementType(cell.settings.measurementTypeId).description
                                }}<em
                                    v-if="getMeasurementType(cell.settings.measurementTypeId).formConfig.required">*</em></v-card-subtitle>
                            </div>
                                </template>
                            
                                    <eod-measurement
                                    v-if="value && value.job && value.job.project && value.job.jobType && getMeasurementIndexByCell(cell) != null && getMeasurementType(cell.settings.measurementTypeId)"
                                    ref="measurements" create-task @onCreateTask="value => $emit('onCreateTask', value)"
                                    :cell="cell" :product="productsByCell[cell.id]"
                                    :measurementType="getMeasurementType(cell.settings.measurementTypeId)"
                                    :value="value.measurements[getMeasurementIndexByCell(cell)]"
                                    :projectId="value.job.project.id" :jobTypeId="value.job.jobType.id"
                                    @input="updateCellMeasurement" :readonly="readonly"></eod-measurement>
                                    <v-alert class="mb-0" color="info" text v-else-if="getMeasurementIndexByCell(cell) == null">
                                        Kon instellingen van de meetwaarde niet ophalen.
                                    </v-alert>
                        </eod-card>
                    </v-lazy>
                    
                    
                    <div class="d-flex">
                        <v-btn class="mb-4" large text v-if="isPreviousStep()" @click="goToPreviousStep">{{
                            getTranslation('Vorige')
                        }}</v-btn>
                        <v-spacer />
                        <v-btn class="mb-4" large v-if="isNextStep()" color="secondary" @click="goToNextStep">
                            {{ getTranslation('Volgende') }}
                        </v-btn>
                        <v-btn class="mb-4" large v-if="!isNextStep() && sendButton && !readonly" color="secondary"
                            @click="$emit('submit')">
                            {{ getTranslation('Verzenden') }}
                        </v-btn>
                    </div>
                </div>
            </template>
        </div>
        </v-form>
        <eod-dialog width="1200" :inset="false" v-if="activeProduct" v-model="isProductDialogVisible" :title="activeProduct.name" icon="mdi-pencil-outline" iconColor="primary">
            <eod-product-detail ref="productDetail" hide-measurement-types hide-storage-search hide-header :value="activeProduct.id?activeProduct.id:activeProduct" @saved="productSaved" @saving="val => {isProductLoading = val;}"></eod-product-detail>
            <template v-slot:actions>
                <v-btn :loading="isProductLoading" @click="saveProductAction" large rounded block depressed :color="isSaveSuccess?'success':'secondary'" :disabled="isSaveSuccess">
                    <v-icon v-if="isSaveSuccess">mdi-check-circle</v-icon>
                    <template v-else><v-icon left>mdi-plus</v-icon>Opslaan</template>
                </v-btn>
            </template>
        </eod-dialog>
        <eod-dialog width="1200" v-if="activeVariable" :inset="false" hide-ok v-model="isChangeVariableDialogVisible" :title="activeVariable.text" icon="mdi-pencil-outline" iconColor="primary">
            <!-- <div class="py-4 px-4">
                <eod-text-field label="Zoeken" @input="updateAssetSearch"></eod-text-field>
            </div> -->
            <eod-objects-treeview :open="[value.job.project.id]" readonly hide-extra :search="$helper.filtersToVariables(searchFilters)" @selected="updateVariable" :selectable="['DEVICE']" :classes="['COMPANY', 'PROJECT', 'DEVICE', 'DEVICE_FOLDER']" class-name="DEVICE" object-name="Product"></eod-objects-treeview>
        </eod-dialog>
        <eod-delete-dialog v-if="activeCell" @delete="deleteCellAction" v-model="isDeleteCellDialogVisible" :item="getMeasurementType(activeCell.settings.measurementTypeId)"></eod-delete-dialog>
        <v-btn v-if="$isMobile" class="btn-back-to-top" :class="scrollTop ? 'show' : ''" @click="backToTop" fab small color="primary"
            dark><v-icon>mdi-chevron-up</v-icon></v-btn>
    </div>
</template>
<style lang="scss" scoped>
.btn-back-to-top {
    position: fixed;
    right: -60px;
    bottom: 65px;
    z-index: 2;

    opacity: 0;
    transition: .5s all;

    &.show {
        right: 5px;
        opacity: 1;
    }
}
</style>
<script>
import eodMeasurement from './eod-measurement.vue';
import eodArticleStockMovement from './fieldtypes/eod-article-stock-movement.vue'
import eodObjectsTreeviewInput from './eod-objects-treeview-input.vue';
import eodDeleteDialog from './eod-delete-dialog.vue';
import eodProductDetail from './eod-product-detail.vue';
import eodObjectsTreeview from './eod-objects-treeview.vue';
import Product from '../models/product';
import eodSearch from './eod-search.vue';
import _ from 'lodash';

export default {
    name: 'eod-task-form',
    components: {
        eodMeasurement,
        eodObjectsTreeviewInput,
        eodDeleteDialog,
        eodArticleStockMovement,
        eodProductDetail,
        eodObjectsTreeview,
        eodSearch
    },
    props: {
        value: Object | Array,
        template: {
            type: Object,
            default: null
        },
        lang: {
            type: String,
            default: 'nl'
        },
        sendButton: {
            type: Boolean,
            default: true
        },
        readonly: {
            type: Boolean,
            default: false
        },
        hidePage: {
            type: Boolean,
            default: false
        },
    },
    data: () => {
        return {
            deleteArticleIndex: null,
            isDeleteArticleDialogVisible:false,
            isAddArticleDialogVisible:false,
            articleSearchFilters:[],
            articleSearchTimeout: null,
            stepIndex: 0,
            step: null,
            loading: false,
            translationsLoading: false,
            translations: {},
            scrollTop: false,
            scrollInterval: null,
            articles: {},
            measurementTypes: [],
            measurementTypesResolved: {},
            products: [],
            productsByCell: {},
            readonlyVariant: false,
            visibleIndex: 0,
            activeCell: null,
            isDeleteCellDialogVisible: false,
            isProductDialogVisible: false,
            isChangeVariableDialogVisible: false,
            isActionsDialogVisible: false,
            isProductLoading: false,
            isSaveSuccess: false,
            isProductCreate: false,
            activeProduct: null,
            activeVariable: null,
            searchConfig: Product.searchConfig,
            searchFilters: [],
        }
    },
    computed: {
        variantCells() {
            if (!this.step) {
                return [];
            }

            let cells = [];
            for (let i = 0; i < this.step.cells.length; i++) {
                const cell = this.step.cells[i];
                if (['measurementType'].includes(cell.typeId)) {
                    if ((!cell.settings.variants || cell.settings.variants.length == 0) || cell.settings.variants.includes(this.template.content.variant)) {
                        cells.push(cell);
                    }
                }
            }

            return cells;
        },
        variantsCheck() {
            return (!this.template.content.variants || this.template.content.variants.length <= 0 || (this.template.content.variants && this.template.content.variant));
        },
        variablesCheck() {
            let check = true;
            if (this.variantVariables) {
                for (let i = 0; i < this.variantVariables.length; i++) {
                    const variable = this.variantVariables[i];
                    if (!variable.product) {
                        check = false;
                    }
                }
            }
            return check;
        },
        variantVariables() {
            let variables = [];
            if (!this.template.content.variables) {
                return null;
            }

            let variableIds = [];
            let cells = this.variantCells;
            for (let i = 0; i < cells.length; i++) {
                const cell = cells[i];
                if (cell.typeId == 'measurementType' && cell.settings.product && !variableIds.includes(cell.settings.product.variable)) {
                    variableIds.push(cell.settings.product.variable);
                }
            }

            for (let i = 0; i < this.template.content.variables.length; i++) {
                let variable = this.template.content.variables[i];
                if (variableIds.includes(variable.value)) {
                    variable.index = i;

                    if(variable.product && variable.product.id){
                        variable.product = this.getProductById(variable.product.id);
                    }
                    variables.push(variable);
                }
            }

            return variables;
        }
    },
    mounted() {
        if(this.value && this.value.job && this.value.job.articles){
            for (let i = 0; i < this.value.job.articles.length; i++) {
                const article = this.value.job.articles[i];
                this.articles[article.id] = article;
            }
        }

        if(this.value && this.value.job && this.value.job.project && this.value.job.project.id){

            const projectSearchConfig = this.$helper.getArrayItemByProperty(this.searchConfig, 'label', 'Project');
            projectSearchConfig.readonly = true;

            this.searchFilters =  [
                {
                    "config": projectSearchConfig,
                    "result": {
                        "name": this.value.job.project.name,
                        "id": this.value.job.project.id,
                        "search_title": this.value.job.project.name
                    }
                }
            ] ;
        }

        if (this.template) {
            this.loading = true;

            this.loadMeasurementTypesOfTemplate()
                .then(() => {
                    if (this.variantsCheck && this.variablesCheck) {
                        return this.loadProductsOfTemplate();
                    }

                    return true;
                }).then(() => {
                    if (this.template.content) {
                        let steps = this.getSteps();
                        if (steps && steps[this.stepIndex]) {
                            this.step = steps[this.stepIndex];
                        }

                        if (this.template.content.variant) {
                            this.readonlyVariant = true;
                        }
                    }
                }).then(() => {
                    return this.loadTranslations();
                }).then(() => {
                    this.enableBackToTop();
                }).finally(() => {
                    this.$emit('loaded');
                    this.loading = false;
                });
        }
    },
    beforeDestroy() {
        clearInterval(this.scrollInterval);
    },
    watch: {
        stepIndex(val) {
            this.loadTranslations();
        },
        lang(val) {
            this.refresh();
        }
    },
    methods: {
        showDeleteArticleDialog(index){
            this.deleteArticleIndex = index;
            this.isDeleteArticleDialogVisible = true;
        },
        deleteArticleAction(){
            const val = this.value;
            val.job.articles.splice(this.deleteArticleIndex, 1);
            this.$emit('input', val);

            this.isDeleteArticleDialogVisible = false;
            this.deleteArticleIndex = null;
        },
        addArticleAction(article){
            const val = this.value;
            val.job.articles.push({
                id: article.id,
                name: article.name,
                articleType: {
                    id: article.typeId,
                    name: article.typeName,
                },
                quantity: -1
            });
            this.$emit('input', val);

            this.isAddArticleDialogVisible = false;
        },
        updateArticleSearch(searchVal){

            clearTimeout(this.articleSearchTimeout);

            this.articleSearchTimeout = setTimeout(() => {
                const nameSearchConfig = this.$helper.getArrayItemByProperty(this.searchConfig, 'filter', 'tree_objects_view.name');

                if(!searchVal || searchVal == ''){
                    this.articleSearchFilters = [];
                } else {
                    this.articleSearchFilters = [{
                        config: nameSearchConfig,
                        result: {
                            name: searchVal,
                            id: searchVal,
                            search_title: searchVal
                        }
                    }]
                }
            }, 500);
        },
        saveProductAction(){
            if(this.$refs.productDetail){
                this.$refs.productDetail.saveItem();
            }
        },
        productSaved(productId){
            this.isSaveSuccess = true;

            if(this.isProductCreate){
                this.updateVariable({id: productId});
                this.isProductCreate = false;
            }else if (this.variantsCheck && this.variablesCheck) {
                this.loadProductsOfTemplate();
            }

            setTimeout(() => {
                this.isProductDialogVisible = false;
                this.isActionsDialogVisible = false;
                this.activeProduct = null;
                this.isSaveSuccess = false;
            }, 2000);
            
        },
        updateAssetSearch(searchVal){
            const nameSearchConfig = this.$helper.getArrayItemByProperty(this.searchConfig, 'filter', 'tree_objects_view.name');

            if(!searchVal || searchVal == ''){
                for (let i = 0; i < this.searchFilters.length; i++) {
                    const filter = this.searchFilters[i];
                    if(filter.config.filter == 'tree_objects_view.name'){
                        this.searchFilters.splice(i, 1);
                        break;
                    }
                }

                return;
            }

            let found = false;
            for (let i = 0; i < this.searchFilters.length; i++) {
                const filter = this.searchFilters[i];
                if(filter.config.filter == 'tree_objects_view.name'){
                    this.searchFilters[i].result.id = searchVal;
                    found = true;
                    break;
                }
            }

            if(!found){
                this.searchFilters.push({
                    config: nameSearchConfig,
                    result: {
                        name: searchVal,
                        id: searchVal,
                        search_title: searchVal
                    }
                })
            }
        },
        showActionsDialog(variable){

            this.activeVariable = null;

            this.$nextTick(() => {
                this.activeVariable = variable;
                this.isActionsDialogVisible = true;
            });            
        },
        changeAsset(){
            this.isChangeVariableDialogVisible = true;
        },
        updateVariable(product){
            if(product && this.activeVariable){
                if(this.template.content.variables[this.activeVariable.index]){
                    this.template.content.variables[this.activeVariable.index].product.id = product.id;
                    this.updateTemplate();
                }
            }

            this.isChangeVariableDialogVisible = false;
            this.isActionsDialogVisible = false;
            
            this.activeVariable = null;
        },
        editAsset(){
            this.isProductCreate = false;
            this.activeProduct = null;

            this.$nextTick(() => {
                if(this.activeVariable.product && this.activeVariable.product.id){
                    this.activeProduct = this.activeVariable.product;
                }

                this.isProductDialogVisible = true;
            })
        },
        createAsset(){

            this.activeProduct = null;
            this.isProductCreate = true;

            this.$nextTick(() => {

                this.activeProduct = {
                    class:'DEVICE',
                    productType: {},
                    statusCode: {},
                    name: this.activeVariable.text
                }

                if(this.value.job && this.value.job.project && this.value.job.project.id){
                    this.activeProduct.treeParentId = this.value.job.project.id;
                }

                this.isProductDialogVisible = true;

                this.$nextTick(() => {
                    if(this.$refs.productDetail && this.activeProduct.treeParentId){
                        this.$refs.productDetail.parentChanged(this.activeProduct.treeParentId);
                    }
                });
            });
        },
        updateCellArticleStockMovement(index, articleStockMovement){
            const val = this.value;
            val.job.articles[index] = articleStockMovement;
            this.$emit('input', val);
        },
        deleteCell(cell){
            this.activeCell = cell;
            // Show delete dialog

            this.isDeleteCellDialogVisible = true;
        },
        deleteCellAction(){
            this.$emit('deleteCell', this.activeCell);
            this.isDeleteCellDialogVisible = false;
        },
        async updateTemplate() {
            this.$emit('updateTemplate', this.template);

            if (this.variantsCheck && this.variablesCheck) {
                await this.loadProductsOfTemplate();
            }

            this.$forceUpdate();
        },
        getProductById(productId){
            return this.$helper.getArrayItemByProperty(this.products, 'id', productId);
        },
        getProduct(cell) {
            return this.productsByCell[cell.id];
        },
        updateCellMeasurement(measurement) {
            let response = this.value;

            response.measurements[this.getMeasurementIndex(measurement)].value = measurement.value;

            this.$emit('input', response);
        },
        getMeasurementIndex(searchMeasurement) {
            for (let i = 0; i < this.value.measurements.length; i++) {
                const measurement = this.value.measurements[i];
                if (measurement.measurementType.id != searchMeasurement.measurementType.id) {
                    continue;
                }

                if (searchMeasurement.product && searchMeasurement.product.id != measurement.product.id) {
                    continue;
                }

                if (!searchMeasurement.product && measurement.product != null) {
                    continue;
                }

                return i;
            }
        },
        getMeasurementIndexByCell(cell) {

            if (!this.value || !this.value.measurements || !cell.settings.measurementTypeId) {
                return null;
            }

            let response = this.value;

            const measurementType = this.getMeasurementType(cell.settings.measurementTypeId);
            const product = this.getProduct(cell);

            if(!measurementType){
                return null;
            }

            let latestMeasurementIndex = null; //Fix for app sending multiple measurements for same MeasurementType/Product
            for (let i = 0; i < this.value.measurements.length; i++) {
                const measurement = this.value.measurements[i];
                if (measurement.measurementType.id != measurementType.id) {
                    continue;
                }

                if (product && measurement.product && product.id != measurement.product.id) {
                    continue;
                }

                if (!product && measurement.product != null) {
                    continue;
                }

                if (latestMeasurementIndex == null || (latestMeasurementIndex != null && this.$moment(this.value.measurements[latestMeasurementIndex].time).isBefore(this.$moment(measurement.time)))) {
                    latestMeasurementIndex = i;
                }
            }

            if (latestMeasurementIndex != null) {
                return latestMeasurementIndex;
            }

            if (this.variantsCheck && this.variablesCheck && measurementType) {

                let measurement = {
                    organization: { id: this.$eod.getRealm() },
                    owner: { id: this.$eod.getUser().id },
                    measurementType: { id: measurementType.id },
                    value: null,
                    isCreate: true
                };

                if (product) {
                    measurement.product = { id: product.id };
                }

                response.measurements.push(measurement);

                this.$emit('input', response);
            }

            return response.measurements.length - 1;
        },
        getProductIdOfCell(cell) {
            if (cell.typeId == 'measurementType' && cell.settings.product) {
                if (cell.settings.product.type == 'dynamic') {
                    for (let variableIndex = 0; variableIndex < this.template.content.variables.length; variableIndex++) {
                        const variable = this.template.content.variables[variableIndex];
                        if (variable.value == cell.settings.product.variable && variable.product) {
                            return variable.product.id;
                        }
                    }
                } else {
                    return cell.settings.product.id;
                }
            }

            return null;
        },
        async loadProductsOfTemplate() {
            let productIds = [];

            if (!this.template.content) {
                return false;
            }

            for (let pageIndex = 0; pageIndex < this.template.content.pages.length; pageIndex++) {
                const page = this.template.content.pages[pageIndex];
                for (let cellIndex = 0; cellIndex < page.cells.length; cellIndex++) {
                    const cell = page.cells[cellIndex];
                    const productId = this.getProductIdOfCell(cell);

                    if (productId) {
                        productIds.push(productId);
                    }
                }
            }

            this.productsByCell = {};

            if(productIds.length > 0){
                await this.$eod.get('products', ['id name'], {
                    whereIn: {
                        column: 'id',
                        array: productIds
                    }
                }).then(response => {
                    if (response.data.data.products) {

                        this.products = response.data.data.products.edges;

                        for (let pageIndex = 0; pageIndex < this.template.content.pages.length; pageIndex++) {
                            const page = this.template.content.pages[pageIndex];
                            for (let cellIndex = 0; cellIndex < page.cells.length; cellIndex++) {
                                const cell = page.cells[cellIndex];
                                const productId = this.getProductIdOfCell(cell);
                                this.productsByCell[cell.id] = this.getProductById(productId);
                            }
                        }
                    }
                });
            }
            
        },
        loadMeasurementTypesOfTemplate() {
            let measurementTypeIds = [];

            if (!this.template.content) {
                return false;
            }

            for (let pageIndex = 0; pageIndex < this.template.content.pages.length; pageIndex++) {
                const page = this.template.content.pages[pageIndex];
                for (let cellIndex = 0; cellIndex < page.cells.length; cellIndex++) {
                    const cell = page.cells[cellIndex];
                    if (cell.typeId == 'measurementType' && cell.settings.measurementTypeId) {
                        measurementTypeIds.push(cell.settings.measurementTypeId);
                    }
                }
            }

            return this.$eod.get('measurementTypes', ['id name icon description unit{id name} typeConfig valueConfigList{id name itemType description listItems{id name isActive op low high threshold stepSize} isActive} valueConfigListItems{id name isActive op low high threshold stepSize} formConfig{category type subCategory enabled required photo photoRequired comment commentRequired default defaultValue values}'], {
                whereIn: {
                    column: 'id',
                    array: measurementTypeIds
                }
            }).then(response => {
                if (response.data.data.measurementTypes) {
                    this.measurementTypes = response.data.data.measurementTypes.edges;
                }
            });

        },
        validate() {
            let valid = true;
            if(this.$refs.measurements){
                for (let i = 0; i < this.$refs.measurements.length; i++) {
                    const measurement = this.$refs.measurements[i];
                    if(!measurement.validate()){
                        valid = false;
                    };
                }
            }
            

            if(this.$refs.form){
                if(!this.$refs.form.validate()){
                    valid = false;
                };
            }

            return valid;
        },
        getSteps() {
            let steps = Object.assign([], this.template.content.pages);

            // Check rules
            let rules = this.template.content.rules;

            if (rules) {
                for (let i = 0; i < rules.length; i++) {
                    const rule = rules[i];
                    if (rule.action == 'hideStep') {
                        switch (rule.operator) {
                            case '=':
                                if (typeof rule.values == 'number' && rule.values == this.getMeasurementType(rule.measurementTypeId).value) {
                                    for (let j = 0; j < rule.actionValues.length; j++) {
                                        const stepIndex = this.getStepIndexById(steps, rule.actionValues[j]);
                                        steps.splice(stepIndex, 1);
                                    }
                                } else if (typeof rule.values == 'object' && rule.values.includes(this.getMeasurementType(rule.measurementTypeId).value)) {
                                    for (let j = 0; j < rule.actionValues.length; j++) {
                                        const stepIndex = this.getStepIndexById(steps, rule.actionValues[j]);
                                        steps.splice(stepIndex, 1);
                                    }
                                }
                                break;
                            case '<>':
                                if (typeof rule.values == 'number' && rule.values != this.getMeasurementType(rule.measurementTypeId).value) {
                                    for (let j = 0; j < rule.actionValues.length; j++) {
                                        const stepIndex = this.getStepIndexById(steps, rule.actionValues[j]);
                                        steps.splice(stepIndex, 1);
                                    }
                                } else if (typeof rule.values == 'object' && !rule.values.includes(this.getMeasurementType(rule.measurementTypeId).value)) {
                                    for (let j = 0; j < rule.actionValues.length; j++) {
                                        const stepIndex = this.getStepIndexById(steps, rule.actionValues[j]);
                                        steps.splice(stepIndex, 1);
                                    }
                                }
                                break;
                        }
                    }
                }
            }

            return steps;
        },
        getStepIndexById(steps, id) {
            for (let i = 0; i < steps.length; i++) {
                const step = steps[i];
                if (step.id == id) {
                    return i;
                }
            }

            return null;
        },
        refresh() {
            for (let i = 0; i < this.$refs.measurements.length; i++) {
                const measurement = this.$refs.measurements[i];
                measurement.refresh();
            }
            this.$forceUpdate();
        },
        isNextStep() {
            let steps = this.getSteps();

            if (steps) {
                if (steps[this.stepIndex + 1]) {
                    return true;
                }
            }

            return false;
        },
        isPreviousStep() {
            let steps = this.getSteps();

            if (steps) {
                if (steps[this.stepIndex - 1]) {
                    return true;
                }
            }

            return false;
        },
        getMeasurementType(measurementTypeId) {
            if (!this.measurementTypes) {
                return null;
            }

            if(this.measurementTypesResolved[measurementTypeId]){
                return this.measurementTypesResolved[measurementTypeId];
            }

            let measurementType = this.measurementTypes[this.getMeasurementTypeIndex(measurementTypeId)];
            if (measurementType) {
                measurementType = this.translateListItems(measurementType);
            }

            this.measurementTypesResolved[measurementTypeId] = measurementType;

            return measurementType;
        },
        getMeasurementTypeIndex(measurementTypeId) {
            for (let i = 0; i < this.measurementTypes.length; i++) {
                const element = this.measurementTypes[i];
                if (element.id == measurementTypeId) {
                    return i;
                }
            }

            return null;
        },
        setStepIndex(stepIndex){
            this.stepIndex = stepIndex;
            this.initStep();
        },
        goToNextStep() {
            this.stepIndex++;
            this.initStep();
        },
        goToPreviousStep() {
            this.stepIndex--;
            this.initStep();
        },
        initStep(){
            this.loading = true;
            let steps = this.getSteps();
            if (steps && steps[this.stepIndex]) {
                this.step = steps[this.stepIndex];
            }

            this.$nextTick(() => {
                this.$forceUpdate();
                this.backToTop();
                this.loading = false;
            });
        },
        translateListItems(measurementType) {
            if (measurementType.valueConfigListItems && measurementType.valueConfigListItems.length > 0) {
                for (let i = 0; i < measurementType.valueConfigListItems.length; i++) {
                    const listItem = measurementType.valueConfigListItems[i];
                    if (!listItem.originalName) {
                        measurementType.valueConfigListItems[i].originalName = listItem.name;
                    }

                    let name = measurementType.valueConfigListItems[i].originalName;
                    measurementType.valueConfigListItems[i].name = this.getTranslation(name);
                }
            } else if (measurementType.valueConfigList && measurementType.valueConfigList.listItems && measurementType.valueConfigList.listItems.length > 0) {
                for (let i = 0; i < measurementType.valueConfigList.listItems.length; i++) {
                    const listItem = measurementType.valueConfigList.listItems[i];
                    if (!listItem.originalName) {
                        measurementType.valueConfigList.listItems[i].originalName = listItem.name;
                    }

                    let name = measurementType.valueConfigList.listItems[i].originalName;
                    measurementType.valueConfigList.listItems[i].name = this.getTranslation(name);
                }
            }

            return measurementType;
        },
        loadTranslations() {
            if (!this.translations[this.stepIndex] && this.step) {
                this.translationsLoading = true;
                let keys = ['Vorige', 'Volgende', 'Verzenden'];
                for (let i = 0; i < this.step.cells.length; i++) {
                    const cell = this.step.cells[i];

                    if (cell.typeId == "measurementType") {
                        if (cell.settings) {
                            let measurementType = this.getMeasurementType(cell.settings.measurementTypeId);
                            if (measurementType && cell.settings.showDescription && measurementType.description && !keys.includes(measurementType.description)) {
                                keys.push(measurementType.description);
                            }
                            if (measurementType && cell.settings.showTitle && measurementType.name && !keys.includes(measurementType.name)) {
                                keys.push(measurementType.name);
                            }

                            if (measurementType && measurementType.valueConfigListItems && measurementType.valueConfigListItems.length > 0) {
                                for (let i = 0; i < measurementType.valueConfigListItems.length; i++) {
                                    const listItem = measurementType.valueConfigListItems[i];
                                    if (!keys.includes(listItem.name)) {
                                        keys.push(listItem.name);
                                    }

                                }
                            } else if (measurementType && measurementType.valueConfigList && measurementType.valueConfigList.listItems && measurementType.valueConfigList.listItems.length > 0) {
                                for (let i = 0; i < measurementType.valueConfigList.listItems.length; i++) {
                                    const listItem = measurementType.valueConfigList.listItems[i];
                                    if (!keys.includes(listItem.name)) {
                                        keys.push(listItem.name);
                                    }
                                }
                            }
                        }
                    } else if (['text', 'title', 'subtitle'].includes(cell.typeId)) {
                        if (cell.name && !keys.includes(cell.name)) {
                            keys.push(cell.name);
                        }

                        if (cell.typeId == 'text' && cell.settings.collapsableTitle && !keys.includes(cell.settings.collapsableTitle)) {
                            keys.push(cell.settings.collapsableTitle);
                        }
                    }
                }

                if (keys.length > 0) {
                    return this.$eod.get('resolveListItems', ['id', 'name', 'fields{name translations{locale value}}'], {
                        whereIn: {
                            column: 'name',
                            array: keys
                        }
                    })
                        .then(response => {
                            this.translations[this.stepIndex] = response.data.data.resolveListItems.edges;
                            //this.translateListItems();
                            this.$forceUpdate();
                        })
                        .finally(() => {
                            this.translationsLoading = false;
                        });
                } else {
                    this.translationsLoading = false;
                }
            }

            return false;
        },
        getTranslation(key) {
            if (this.translations[this.stepIndex]) {
                for (let i = 0; i < this.translations[this.stepIndex].length; i++) {
                    const listItem = this.translations[this.stepIndex][i];
                    if (listItem.name == key && listItem.fields) {
                        for (let j = 0; j < listItem.fields.length; j++) {
                            const field = listItem.fields[j];
                            if (field.name == 'value' && field.translations) {
                                for (let k = 0; k < field.translations.length; k++) {
                                    const translation = field.translations[k];
                                    if (translation.locale == this.lang && translation.value) {
                                        return translation.value;
                                    }
                                }
                            }
                        }
                    }

                }
            }

            return key;
        },
        enableBackToTop() {
            if(this.$isMobile){
                let self = this;
                let timeout = null;
                this.scrollInterval = setInterval(() => {
                    const form = document.getElementById('response_form');
                    if (form) {
                        if (form.scrollTop > 100 || document.body.scrollTop) {
                            clearTimeout(timeout);
                            timeout = setTimeout(() => {
                                self.scrollTop = false;
                            }, 2000);

                            if (!self.scrollTop) {
                                self.scrollTop = true;
                            }
                        } else {
                            if (self.scrollTop) {
                                self.scrollTop = false;
                            }
                        }
                    }
                }, 500);
            }
        },
        backToTop() {
            const form = document.getElementById('response_form');
            if (form) {
                form.scrollTop = 0; // For Safari
            }

            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
            document.body.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        },
    }
}
</script>