<template>
    <div>
<eod-dialog v-if="eventData" :value="value" @input="val => $emit('input', val)" title="Event aanpassen" :width="1000" icon="mdi-plus" iconColor="primary">
        <div class="pt-4">
            <eod-event ref="eventForm" v-model="eventData"></eod-event>
        </div>
        <template v-slot:actions>
            <v-btn text @click="$emit('input', false)">Sluiten</v-btn>
            <v-spacer></v-spacer>
            <v-btn v-if="eventData.id && !eventData.alarmTemplate" rounded depressed color="error" @click="isDeleteEventDialogVisible = true">Verwijderen</v-btn>
            <v-btn color="primary" depressed @click="saveEvent" :loading="isLoading" rounded><v-icon
                left>mdi-content-save-outline</v-icon>Opslaan</v-btn>
        </template>
        </eod-dialog>
        <eod-delete-dialog v-if="isDeleteEventDialogVisible" v-model="isDeleteEventDialogVisible" :item="{ name: 'event' }"
      title="Event verwijderen" @delete="deleteEvent">
    </eod-delete-dialog>
        </div>
</template>
<script>
import eodEvent from './eod-event.vue';
import Event from './../models/event';
import User from './../models/user';
export default {
    name: 'eod-event-dialog',
    components: {
        eodEvent
    },
    props: {
        value: Boolean,
        itemId: {
            type: String,
        },
        defaultData: {
            type: Object,
        }
    },
    data: () => ({
        eventFields: ['id', 'groupCount', 'eventGroupUnique', 'time', 'alarmTemplate{id content}', 'logs{time}', 'message', 'severity', 'status', 'duration', 'value', 'type', 'facility', 'measurementType{id name}', 'owner{id}', 'responsibleUser{id}', 'project{id name}', 'company{id name}', 'product{id name}', 'storageObjects{id key name}', 'comments{createdAt userId content storageObjects{id key name}}'],
        isDeleteEventDialogVisible: false,
        isLoading: false,
        eventData: null
    }),
    async mounted(){
        let event = null;
        if(this.itemId){
            event = await this.$eod.getById('event', this.itemId, this.eventFields)
            .then(response => {
                return new Event(response.data.data.event);
            });
        } else if(this.defaultData){
            event = new Event(this.defaultData);
        }

        if(event && event.owner){
            event.owner = new User(event.owner);
        }
        this.eventData = event;
    },
    methods: {
        deleteEvent() {
            this.$eod.delete('Event', this.eventData.id).finally(() => {
                this.isDeleteEventDialogVisible = false;
                this.eventData = null;
                this.$emit('deleted');
                this.$emit('input', false);
            });
        },
        async saveEvent() {
            if (this.$refs.eventForm) {
                if (!this.$refs.eventForm.validate()) {
                    return false;
                }
            }

            this.isLoading = true;

            if(this.eventData.alarmTemplate){
                await this.$eod.save('Template', this.eventData.alarmTemplate);
            }

            const data = this.eventData.getSaveData();
            await this.$eod.save('Event', data).then(response => {
                this.eventData = null;
            }).then(() => {
                this.$emit('saved');
                this.$emit('input', false);
            }).finally(() => {
                this.isLoading = false;
            });
        },
    }
}
</script>