<template>
    <span class="material-symbols-sharp" :style="size?'font-size:'+size+'px;':''">
        <slot name="default"></slot>
    </span>
</template>
<script>
export default {
    name: 'eod-icon',
    props:{
        size: Number|String
    }
}
</script>