<template>
    <div v-if="value">
        <div class="mb-4" v-if="value.responses[0].comments && value.responses[0].comments[0]">
            <eod-comments :value="value.responses[0].comments" readonly></eod-comments>
        </div>
        <eod-timetrackings class="mb-4" v-if="value.responses[0].timetrackings" :value="value.responses[0].timetrackings" :min="$moment(value.dueAfterDate).unix()" :max="$moment(value.dueBeforeDate).unix()" open @input="updateTimetrackings"></eod-timetrackings>
        <eod-expansion-panels no-inset :value="0">
            <v-expansion-panel>
                <v-btn small style="position:absolute;right:10px;top:-10px;z-index:5;" color="secondary" fab @click="isAddArticleDialogVisible = true"><v-icon>mdi-plus</v-icon></v-btn>
                <v-expansion-panel-header class="primary white--text">
                    <div>
                    <v-icon dark left>mdi-package-variant</v-icon>
                        <v-badge v-if="value.articles && value.articles.length > 0" color="secondary" :content="value.articles.length">{{$t('Artikelen')}}</v-badge>
                        <span v-else>{{$t('Artikelen')}}</span>
                    </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <eod-expansion-panels no-border>
                        <v-expansion-panel v-for="(article, articleIndex) in value.articles" :key="articleIndex">
                            <v-expansion-panel-header>
                                <div class="text-body-2" v-if="article">
                                    {{article.name}}

                                    <div>
                                        <span v-if="article.quantity > 0">Geproduceerd</span>
                                        <span v-else>Verbruikt</span>: 
                                        <strong>{{ Math.abs(article.quantity) }}</strong>
                                    </div>
                                </div>
                                
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-card-text>
                                <eod-article-stock-movement :value="article" @input="val => updateCellArticleStockMovement(articleIndex, val)"></eod-article-stock-movement>
                        </v-card-text>
                        <v-card-actions class="py-0">
                            <v-spacer></v-spacer><v-btn icon color="error" @click="showDeleteArticleDialog(articleIndex)"><v-icon small>mdi-trash-can-outline</v-icon></v-btn>
                        </v-card-actions>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </eod-expansion-panels>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </eod-expansion-panels>
                        <eod-delete-dialog v-if="deleteArticleIndex != null" v-model="isDeleteArticleDialogVisible" :item="value.articles[deleteArticleIndex]" @delete="deleteArticleAction"></eod-delete-dialog>
                        <eod-dialog width="1200" v-if="isAddArticleDialogVisible" :inset="false" hide-ok v-model="isAddArticleDialogVisible" title="Artikel toevoegen" icon="mdi-pencil-outline" iconColor="primary">
                            <div class="py-4 px-4">
                                <eod-text-field label="Zoeken" @input="updateArticleSearch"></eod-text-field>
                            </div>
                            <div class="pb-4">
                            <eod-objects-treeview readonly hide-extra :search="$helper.filtersToVariables(articleSearchFilters)" @selected="addArticleAction" :selectable="['ARTICLE']" :classes="['ARTICLE_TYPE', 'ARTICLE', 'ARTICLE_FOLDER']" class-name="ARTICLE" object-name="Article"></eod-objects-treeview>
                        </div>
                        </eod-dialog>
        <eod-task-info v-model="value.parent" :show-title="showTitle"></eod-task-info>
    </div>
</template>
<script>
import eodTaskInfo from './eod-task-info.vue';
import eodTimetrackings from './eod-timetrackings.vue';
import eodComments from './eod-comments.vue';
import eodArticleStockMovement from './fieldtypes/eod-article-stock-movement.vue';
import eodObjectsTreeview from './eod-objects-treeview.vue';

export default {
    name:"eod-response-overview",
    components: {
        eodTaskInfo,
        eodTimetrackings,
        eodComments,
        eodArticleStockMovement,
        eodObjectsTreeview
    },
    props: {
        value: Object,
        showTitle:{
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        showProductsDialog: false,
        deleteArticleIndex: null,
        isDeleteArticleDialogVisible: false,
        isAddArticleDialogVisible: false,
        articleSearchFilters: []
    }),
    methods:{
        updateCellArticleStockMovement(index, articleStockMovement){
            const val = this.value;
            val.articles[index] = articleStockMovement;
            this.$emit('input', val);
        },
        updateTimetrackings(timetrackings){
            let job = this.value;
            job.responses[0].timetrackings = timetrackings;
            this.$emit('input', job);
        },
        showDeleteArticleDialog(index){
            this.deleteArticleIndex = index;
            this.isDeleteArticleDialogVisible = true;
        },
        deleteArticleAction(){
            const val = this.value;
            val.articles.splice(this.deleteArticleIndex, 1);
            this.$emit('input', val);

            this.isDeleteArticleDialogVisible = false;
            this.deleteArticleIndex = null;
        },
        addArticleAction(article){
            const val = this.value;
            val.articles.push({
                id: article.id,
                name: article.name,
                articleType: {
                    id: article.typeId,
                    name: article.typeName,
                },
                quantity: -1
            });
            this.$emit('input', val);

            this.isAddArticleDialogVisible = false;
        },
        updateArticleSearch(searchVal){

            clearTimeout(this.articleSearchTimeout);

            this.articleSearchTimeout = setTimeout(() => {
                const nameSearchConfig = this.$helper.getArrayItemByProperty(this.searchConfig, 'filter', 'tree_objects_view.name');

                if(!searchVal || searchVal == ''){
                    this.articleSearchFilters = [];
                } else {
                    this.articleSearchFilters = [{
                        config: nameSearchConfig,
                        result: {
                            name: searchVal,
                            id: searchVal,
                            search_title: searchVal
                        }
                    }]
                }
            }, 500);
        },
    }
}
</script>