import { QUERY_FIELDS, validateQueryTemplate } from "./../../../plugins/queryeditor/config";
import _ from 'lodash';
import eodGraph from './../eod-graph.vue';
import {v4 as uuidv4} from 'uuid';

export default{
    components:{
        eodGraph
    },
    props:{
        value: Object,
        filters: Array,
        variables: Object,
        isEditing: Boolean,
        hideToolbar: Boolean,
        from: Object, // Moment object
        until: Object // Moment object
    },
    data: () => ({
        series: [],
        alarms: [],
        updateKey: 98343,
        id: uuidv4(),
        seriesToFetch: [],
        queryTemplates: {},
        serieEvents: {},
        loading: false,
        measurementTypes: [],
        alarmTemplates: [],
    }),
    methods:{
        getRowFieldData(row, axis, serieIndex){

            if(!axis){
                return null;
            }

            if(!axis || !axis.key){
                return null;
            }

            let key = axis.key;
            if(axis.field && axis.field.key){
                const fieldConfig = QUERY_FIELDS[axis.field.key];
                
                if(typeof serieIndex != undefined){
                    const measurementTypes = this.measurementTypes[serieIndex];
                    if(measurementTypes){
                        const measurementType = Object.values(measurementTypes)[0];
                        if(measurementType && measurementType.formConfig && measurementType.formConfig.type == 'scale'){
                            if(fieldConfig.key == 'value'){
                                const listValues = this.$eod.getMeasurementTypeItems(measurementType);
                                if(listValues[parseInt(row[key])]){
                                    return listValues[parseInt(row[key])].name;
                                }
                            }
                        }
                    }                                 
                }

                if(fieldConfig.type.formatter){
                    return fieldConfig.type.formatter(row[key]);
                } else if(['module', 'treeview'].includes(fieldConfig.type.key) && fieldConfig.endpoint && this.$resolver.get(fieldConfig.endpoint, row[key])){
                    return this.$resolver.get(fieldConfig.endpoint, row[key]).name;
                }
            }

            return row[key];
        },
        exportData(type, serieIndex){
            const label = (this.value.series && this.value.series[serieIndex])?(this.value.series[serieIndex].label||'data'):'data';
            switch(type){
                case 'csv':
                    if(this.series && this.series[serieIndex]){
                        const csv = this.$helper.objectsArrayToCSV(this.series[serieIndex]);
                        this.$helper.downloadText(csv, label+'.csv', 'text/csv;charset=utf-8;');
                    }
                    break;
                case 'image':
                    if (this.$refs[this.id] && this.$refs[this.id].getDataURL) {
                        const imageDataURL = this.$refs[this.id].getDataURL({
                            type: 'png',
                            pixelRatio: 2,
                            backgroundColor: '#FFFFFF',
                            excludeComponents: ['toolbox']
                        });
                        this.$eod.downloadImage(imageDataURL).then(response => {
                            this.$helper.downloadBlob(response.data, label+'.png');
                        });
                    }
                    break;
            }
        },
        getFieldByKey(key){
            return QUERY_FIELDS[key];
        },
        getAxisPropsByFieldKey(axis){

            let key = null;
            if(Array.isArray(axis)){
                if(axis.length > 1){
                    return {
                        // name:field.text,
                        type: 'category',
                        id: 'timeline',
                        axisLabel: {
                            margin: 5,
                            fontSize: 12,
                            lineHeight: 16,
                            color: '#666666'
                        }
                    };
                } else {
                    key = axis[0].field.key;
                }
            } else {
                key = axis.field.key;
            }

            const field = this.getFieldByKey(key);

            if(field.type.key == 'number'){
                return {
                    // name:field.text,
                    type: 'value',
                    scale:false
                };
            } else if (field.type.key == 'timestamp'){
                const settings = {
                    type: 'time',
                    id: 'timeline',
                }

                if(this.from){
                    settings.min = this.from.format('YYYY/MM/DD[T]HH:mm:ss');
                }
                if(this.until){
                    settings.max = this.until.format('YYYY/MM/DD[T]HH:mm:ss');
                }

                return settings;
            } else {
                return {
                    // name:field.text,
                    type: 'category',
                    id: 'timeline'
                };
            }
        },
        mergeTileOptions(){
            
            this.options.color = this.getColors();

            if(this.value.graph && this.value.graph.options){
                this.options = _.merge(this.options, this.value.graph.options);
            }
        },
        mergeSerieOptions(serie, graphSerie){
            let merged = serie;
            if(serie && graphSerie.options){
                merged = _.merge(serie, graphSerie);
            }
            return merged;
        },
        getSerieQueryTemplates(){

            const queryTemplateIds = this.value.series.filter(item => (typeof item.query === 'string' && item.query)).map(item => item.query);
            return this.$resolver.resolve('templates', queryTemplateIds, {fields:['id','name','content']}).then(resolved => {
                const serieTemplates = [];
                for (let i = 0; i < this.value.series.length; i++) {
                    const serie = this.value.series[i];
                    if(typeof serie.query == 'string'){
                        if(serie.query){
                            serieTemplates.push(resolved[serie.query]);
                        }
                        
                    }else {
                        serieTemplates.push(serie.query);
                    }
                }

                return serieTemplates;
            });
        },
        finished() {
            this.$emit('graphLoaded', this.id, this.$refs[this.id]||null);
        },
        zoom(params){
            this.$emit('zoom', params);
        },
        getColors(){
            const colors = [];

            if(this.dashboard && this.dashboard.content.style.color1){
                colors.push(this.dashboard.content.style.color1);
            } else {
                colors.push(this.$eod.getOrganizationSetting('organisation.color.primary').value);
            }

            if(this.dashboard && this.dashboard.content.style.color2){
                colors.push(this.dashboard.content.style.color2);
            } else {
                colors.push(this.$eod.getOrganizationSetting('organisation.color.secondary').value);
            }

            return colors;
        },
        getAlarmFilters(template, row){
            const wheres = [];
            const existing = [];
            if(this.filters){
                for (let i = 0; i < this.filters.length; i++) {
                    const filter = this.filters[i];
                    const fieldName = this.$helper.underscoreToCamelCase(filter.key);
                    wheres.push({
                        column: fieldName,
                        value: filter.value
                    });
                    existing.push(fieldName);
                }
            }
            
            if(template.content && template.content.filters){
                for (let i = 0; i < template.content.filters.length; i++) {
                    const filter = template.content.filters[i];
                    const fieldName = this.$helper.underscoreToCamelCase(filter.field.key);
                    if(!existing.includes(fieldName)){
                        wheres.push({
                            column:fieldName,
                            value: filter.value
                        })
                    }
                }
            }

            const filterFields = ['job_type_id','owner_id','product_id', 'measurement_type_id','project_id','company_id'];
            for (let i = 0; i < filterFields.length; i++) {
                const filterField = filterFields[i];
                if(row && row[filterField]){
                    const fieldName = this.$helper.underscoreToCamelCase(filterField);
                    if(!existing.includes(fieldName)){
                        wheres.push({
                            column:fieldName,
                            value: row[filterField]
                        })
                    }
                }
            }

            return wheres;
        },
        loadEvents(){
            return new Promise(async resolve => {

                const series = this.getSeries();
                for (let i = 0; i < series.length; i++) {
                    const serie = series[i];
            
                        this.serieEvents[i] = await this.$eod.get('events', ['id', 'severity','isMasked','severityCode', 'status', 'message', 'time', 'value'], {
                            where: 
                            [
                                {
                                    column: 'time',
                                    operator: '>',
                                    value: this.from.toISOString()
                                },
                                {
                                    column: 'time',
                                    operator: '<',
                                    value: this.until.toISOString()
                                },
                                ...this.getAlarmFilters(this.queryTemplates[serie.originalSerieIndex], serie.data[0])
                            ]
                        })
                            .then(response => {
                                if(response && response.data && response.data.data && response.data.data.events){
                                    return response.data.data.events.edges;
                                }

                                return [];
                            })
                }

                resolve();
            });
        },
        loadEvent(template, row){
            const options = {
                orderBy: {column: 'severityCode',type: 'desc'},
                where: [
                    {
                        column: 'status',
                        value: 'open'
                    },
                    ...this.getAlarmFilters(template, row)
                ]
            }

            return this.$eod.get('events', ['id', 'severity','isMasked','severityCode', 'status', 'message', 'time'], options)
                .then(response => {
                    if(response && response.data && response.data.data && response.data.data.events){
                        return {
                            event: response.data.data.events.edges[0],
                            filters: template.content?template.content.filters:null
                        };
                    }

                    return {
                        event: null,
                        filters: template.content?template.content.filters:null
                    };
                })
        },
        loadAlarmTemplates(){
            return new Promise(resolve => {
                if(this.queryTemplates && this.queryTemplates[0] && this.series[0] && this.series[0][0]){

                    const alarmFilters = this.getAlarmFilters(this.queryTemplates[0], this.series[0][0]);
                    const relationFilters = {
                        'projectId': { column: 'projectId', relation: 'projects_templates' },
                        'productId': { column: 'productId', relation: 'products_templates' },
                        'measurementTypeId': { column: 'measurementTypeId', relation: 'measurementTypes_templates' },
                        'companyId': { column: 'companyId', relation: 'companies_templates' },
                    };

                    const filters = [];
                    for (let i = 0; i < alarmFilters.length; i++) {
                        const alarmFilter = alarmFilters[i];
                        if(relationFilters[alarmFilter.column]){
                            filters.push({
                                ...relationFilters[alarmFilter.column],
                                array:[alarmFilter.value]
                            });
                        }
                    }

                    this.$eod.get('templates', ['id','content'], {
                        limit: 1,
                        where: [
                            {
                                column:'type',
                                value:'ALARM_RULE'
                            },
                            {
                                column:'content:type',
                                value: 'thresholds'
                            }
                        ],
                        whereInRelated: filters
                    }).then(response => {
                        if(response.data.data.templates.edges[0]){
                            this.alarmTemplates[0] = response.data.data.templates.edges[0];
                        }
                    }).finally(() => {
                        resolve();
                    });
                } else {
                    resolve();
                }
            });
        },
        loadData(options){
            return new Promise(async resolve => {
                this.loading = true;
                this.series = [];
                this.seriesToFetch = {};
                if(this.value && this.value.series){

                    if(this.filters){
                        for (let i = 0; i < this.filters.length; i++) {
                            const filter = this.filters[i];
                            if(filter.key == 'project_id' && filter.value){
                                await this.$resolver.resolve('projects', [filter.value], {fields:['id name company{id name}']});
                            }
                        }
                    }

                    this.queryTemplates = await this.getSerieQueryTemplates();
                    for (let i = 0; i < this.value.series.length; i++) {
                        const serie = this.value.series[i];

                        const unique = uuidv4();
                        this.seriesToFetch[unique] = i;
                        
                        const queryOptions = {};
                        const template = {content:null};

                        if(typeof serie.query === 'object'){
                            template.content = _.cloneDeep(serie.query.content);
                        } else if(this.queryTemplates) {
                            template.content = _.cloneDeep(this.$helper.getArrayItemByProperty(this.queryTemplates, 'id', serie.query).content);
                        }

                        this.measurementTypes[i] = await this.resolveQueryMeasurementType(template);

                        if(!template){
                            return false;
                        }

                        if(validateQueryTemplate(template)[0]){
                            return false;
                        }

                        if(this.from && this.until){
                            queryOptions.from = this.from;
                            queryOptions.until = this.until;
                        } else if(this.$root.$children[0].selectedTime){
                            queryOptions.from = this.$moment.tz(this.$root.$children[0].selectedTime.from, 'Europe/Brussels').utc();
                            queryOptions.until = this.$moment.tz(this.$root.$children[0].selectedTime.until, 'Europe/Brussels').utc();
                        }

                        if(options && options.limit){
                            queryOptions.limit = options.limit;
                        }

                        const filters = [];
                        for (let filterIndex = 0; filterIndex < template.content.filters.length; filterIndex++) {
                            const filter = template.content.filters[filterIndex];
                            filters.push(_.cloneDeep(filter));
                        }
                        if(serie.filters){
                            for (let i = 0; i < serie.filters.length; i++) {
                                const filter = _.cloneDeep(serie.filters[i]);
                                
                                if(typeof filter.value == 'object'){
                                    if(filter.value.isVariable && this.variables[filter.value.id]){
                                        filter.value = this.variables[filter.value.id].id;
                                        filters.push(filter);
                                    }
                                } else {
                                    filters.push(filter);
                                }
                            }
                        }
                
                        this.$eod.queryMeasurements(unique, {
                            content: {
                                ...template.content,
                                filters: filters
                            }
                        }, this.filters, queryOptions).then(async result => {
                            if(result.data){
                                await this.resolveItems(result.data, template);
                                
                                if(this.seriesToFetch.hasOwnProperty(result.unique)){
                                    if(!this.hideToolbar && result.data && result.data[0]){
                                        this.alarms[this.seriesToFetch[result.unique]] = await this.loadEvent(template, result.data[0]);
                                    } else {
                                        this.alarms[this.seriesToFetch[result.unique]] = {}
                                    }
                                    
                                    this.series[this.seriesToFetch[result.unique]] = result.data;
                                    delete this.seriesToFetch[result.unique];
                                }
                            } else {
                               this.series[i] = [];
                            }

                            if(Object.values(this.seriesToFetch).length == 0){
                                this.loading = false;

                                this.$nextTick(resolve);
                            }
                        }).catch((e) => {
                            console.error('Error getting data of serie', template.id, e);
                            this.series[i] = [];
                            if(this.series.length == this.value.series.length){
                                this.loading = false;
                                this.$nextTick(resolve);
                            }
                        });
                    }
                } else {
                    this.loading = false;
                    this.$nextTick(resolve);
                }
            });
        },
        resolveQueryMeasurementType(template){
            const measurementTypeIds = [];
            if(template.content.filters){
                for (let i = 0; i < template.content.filters.length; i++) {
                    const filter = template.content.filters[i];
                    if(filter.field.key == 'measurement_type_id'){
                        if(typeof filter.value == 'object'){
                            if(filter.value.isVariable && this.variables[filter.value.id]){
                                filter.value = this.variables[filter.value.id].id;
                                measurementTypeIds.push(this.variables[filter.value.id].id);
                            }
                        } else {
                            measurementTypeIds.push(filter.value);
                        }
                    }
                }
            }

            return this.$resolver.resolve('measurementTypes', measurementTypeIds, {fields: ['id','name', 'icon','color','unit{name, fields{name}}', 'formConfig{type}', 'valueConfigList{id name description itemType fields{name description isActive}, listItems{id name description low high threshold stepSize color fields{name description isActive}}}', 'valueConfigListItems{id name description low high threshold stepSize color fields{name description isActive}}']});
        },
        getSerieMeasurementType(serieIndex){
                if(this.measurementTypes[serieIndex]){
                    const measurementTypes = Object.values(this.measurementTypes[serieIndex]);
                    if(measurementTypes){
                        return measurementTypes[0];
                    }
                }

                return null;
        },
        getSerieLabel(serieIndex, row, split){

            if(!split){
                split = ' | ';
            }

            const serie = this.value.series[serieIndex];

            let serieLabel = serie.label;

            if(serie.legendFields && serie.legendFields[0]){
                serieLabel = '';

                if(serie.legendFields.includes('measurement_type_id') && !row['measurement_type_id']){
                    let measurementType = this.getSerieMeasurementType(serieIndex);
                    if(measurementType){
                        serieLabel += split + measurementType.name
                    }
                }
    
                for (let k = 0; k < serie.legendFields.length; k++) {
                    const extraSerieField = serie.legendFields[k];
                    const fieldConfig = QUERY_FIELDS[extraSerieField];
                    if(['module', 'treeview'].includes(fieldConfig.type.key) && this.$resolver.get(fieldConfig.endpoint, row[extraSerieField])){
                        if(fieldConfig.endpoint == 'users'){
                            serieLabel += split + this.$resolver.get(fieldConfig.endpoint, row[extraSerieField]).getFullName();
                        } else {
                            serieLabel += split + this.$resolver.get(fieldConfig.endpoint, row[extraSerieField]).name;
                        }
                    }
                }

                serieLabel = serieLabel.substring(split.length);
            }

            return serieLabel;

        },
        getSeries(options){
            if(!options){
                options = {};
            };
            const allSeries = [];
            const assetColors = {};

                for (let i = 0; i < this.value.series.length; i++) {
                    const tileSerie = this.value.series[i];
                    const serieData = this.series[i];

                    const measurementType = this.getSerieMeasurementType(i);

                    if(serieData && tileSerie.xAxis && tileSerie.yAxis){
                        if(tileSerie.extraSeries && tileSerie.extraSeries[0]){
                            const extraSeries = {};

                            for (let j = 0; j < serieData.length; j++) {
                                const row = serieData[j];
                                let serieKey = i;

                                let asset = null;
                                for (let k = 0; k < tileSerie.extraSeries.length; k++) {
                                    const extraSerieField = tileSerie.extraSeries[k];
                                    serieKey += '_'+row[extraSerieField];

                                    if(extraSerieField == 'product_id'){
                                        asset = row[extraSerieField];
                                    }
                                }

                                if(!extraSeries[serieKey]){

                                    // Count amount of samen measurementTypes to use asset color instead of measurementType
                                    if(measurementType){
                                        if(assetColors[measurementType.id]){
                                            assetColors[measurementType.id].count++;
                                        } else {
                                            assetColors[measurementType.id] = {
                                                count: 1,
                                                colors: {}
                                            };
                                        }

                                        if(asset){
                                            const assetItem = this.$resolver.get('products', asset);
                                            if(assetItem){
                                                assetColors[measurementType.id].colors[serieKey] = assetItem.color;
                                            }
                                            
                                        }
                                    }

                                    // Add filters to serie for adding event
                                    const queryTemplate = this.queryTemplates[i]||null;
                                    let serieFilters = {};
                                    if(queryTemplate){
                                        serieFilters = this.getAlarmFilters(queryTemplate, row);
                                    }
                                    

                                    let serieLabel = this.getSerieLabel(i, row, options.labelSplit);

                                    extraSeries[serieKey] = {
                                        name: serieLabel,
                                        originalSerieIndex: i,
                                        serieFilters: serieFilters,
                                        key: serieKey,
                                        data: [],
                                        type: 'line',
                                        symbolSize: 14,
                                        showSymbol: false,
                                        connectNulls: false,
                                        itemStyle:{
                                            color: measurementType && measurementType.color?measurementType.color:null
                                        },
                                        ...options.serieOptions,
                                        ...tileSerie.options||null
                                    }
                                }

                                extraSeries[serieKey].data.push({
                                    value: [this.options.xAxis && this.options.xAxis.type == 'time'?row[tileSerie.xAxis.key]:this.getRowFieldData(row, tileSerie.xAxis), this.options.yAxis && this.options.yAxis.type == 'time'?row[tileSerie.yAxis.key]:this.getRowFieldData(row, tileSerie.yAxis), measurementType]
                                });
                            }

                            for (const serieKey in extraSeries) {
                                if (Object.prototype.hasOwnProperty.call(extraSeries, serieKey)) {
                                    const serie = extraSeries[serieKey];
                                    allSeries.push(serie);
                                }
                            }
                        } else {
                            let serie = {
                                name: tileSerie.label,
                                originalSerieIndex: i,
                                key: i,
                                data: [],
                                type: 'line',
                                symbolSize: 14,
                                showSymbol: false,
                                connectNulls: false,
                                itemStyle:{
                                            color: measurementType && measurementType.color?measurementType.color:null
                                        },
                                ...options.serieOptions,
                                ...tileSerie.options||null
                            };
                            
                            for (let j = 0; j < serieData.length; j++) {
                                const row = serieData[j];
                                serie.data.push({
                                    value: [this.options.xAxis && this.options.xAxis.type == 'time'?row[tileSerie.xAxis.key]:this.getRowFieldData(row, tileSerie.xAxis), this.options.yAxis && this.options.yAxis.type == 'time'?row[tileSerie.yAxis.key]:this.getRowFieldData(row, tileSerie.yAxis), measurementType]
                                });
                            }

                            allSeries.push(serie);
                        }
                    }
                }

                // Apply correct color to serie
                for (const measurementTypeId in assetColors) {
                    if (Object.prototype.hasOwnProperty.call(assetColors, measurementTypeId)) {
                        const colorprops = assetColors[measurementTypeId];
                        if(colorprops.count > 1){
                            for (const serieKey in colorprops.colors) {
                                if (Object.prototype.hasOwnProperty.call(colorprops.colors, serieKey)) {
                                    const color = colorprops.colors[serieKey];
                                    for (let i = 0; i < allSeries.length; i++) {
                                        const serie = allSeries[i];
                                        if(serie.key == serieKey){
                                            serie.itemStyle.color = color;
                                            break;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                allSeries.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

                return allSeries;
        },
        async resolveItems(data, template){
            const fields = template.content.fields;
            const toResolve = {};
            for (let i = 0; i < fields.length; i++) {
                const field = fields[i];
                const fieldConfig = QUERY_FIELDS[field.key];
                const fieldName = field.aggregation?field.aggregation+'_'+field.key:field.key;
    
                if(fieldConfig.endpoint){
                    for (let j = 0; j < data.length; j++) {
                        const row = data[j];
                        if(row[fieldName]){
                            if(!toResolve[fieldConfig.endpoint]){
                                toResolve[fieldConfig.endpoint] = {
                                    ids: [],
                                    options: {
                                        fields: fieldConfig.queryFields
                                    }
                                };
                            }
                            toResolve[fieldConfig.endpoint].ids.push(row[fieldName]);
                        }
                    }
                }
            }

            for (const endpoint in toResolve) {
                if (Object.prototype.hasOwnProperty.call(toResolve, endpoint)) {
                    await this.$resolver.resolve(endpoint, toResolve[endpoint].ids, toResolve[endpoint].options);
                }
            }
        },
    }
}