import User from './../models/user.js';
import Project from './../models/project.js';
import Product from './../models/product.js';

export default class DashboardResolver {
    eod = null
    resolved = {}
    models = {
        users: User,
        projects: Project,
        products: Product
    }
    constructor(eod){
        this.eod = eod;
    }

    get(endpoint, id){
        if(this.resolved[endpoint] && this.resolved[endpoint][id]){
            return this.resolved[endpoint][id];
        }
        return null;
    }
    async resolve(endpoint, ids, options){
        return this.getDataFromAPI(endpoint, ids, options).then(() => {
            const returnItems = {};
            for (let i = 0; i < ids.length; i++) {
                const id = ids[i];
                returnItems[id] = this.resolved[endpoint][id];
            }

            return returnItems;
        });
    }
    getDataFromAPI(endpoint, ids, options){
        
        return new Promise(resolve => {
            const filteredIds = ids.filter((value, index, array) => (array.indexOf(value) == index) && value);
            const resolveIds = [];

            if(!this.resolved[endpoint]){
                this.resolved[endpoint] = {}
            }

            for (let i = 0; i < filteredIds.length; i++) {
                const id = filteredIds[i];
                if(!this.resolved[endpoint][id]){
                    resolveIds.push(id);
                }
            }

            let fields = ['id','name'];

            if(options && options.fields){
                fields = options.fields;
            } else if(endpoint == 'users'){
                fields = ['id','firstName','lastName','username'];
            }

            if(resolveIds[0]){
                this.eod.get(endpoint, fields, {
                    whereIn:[
                        {
                            column: 'id',
                            array: resolveIds
                        }
                    ]
                }).then(response => {
                    if(response.data && response.data.data && response.data.data[endpoint]){
                        for (let i = 0; i < response.data.data[endpoint].edges.length; i++) {
                            let resolvedItem = response.data.data[endpoint].edges[i];
            
                            if(this.models[endpoint]){
                                resolvedItem = new this.models[endpoint](resolvedItem);
                            }
    
                            this.resolved[endpoint][resolvedItem.id] = resolvedItem;
                        }

                        const returnItems = {};
                        for (let i = 0; i < ids.length; i++) {
                            const id = ids[i];
                            returnItems[id] = this.resolved[endpoint][id];
                        }
    
                        resolve(returnItems);
                    }
                });
            } else {
                resolve();
            }
        });
    }
}