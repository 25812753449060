<template>
    <div>
        <v-data-table :items="templates" :headers="headers" dense>
            <template v-slot:item.tags="{item}">
                <v-chip-group column>
                        <v-chip small v-if="item.jobTypes && item.jobTypes[0]"
                            :color="inRelatedFilter('jobTypeId', item.jobTypes[0].id) ? 'primary' : null"><v-icon small
                                left>mdi-form-select</v-icon>{{ item.jobTypes[0].name }}</v-chip>
                        <v-chip small v-if="item.projects && item.projects[0]"
                            :color="inRelatedFilter('projectId', item.projects[0].id) ? 'primary' : null"><v-icon small
                                left>mdi-folder-open-outline</v-icon>{{ item.projects[0].name }}</v-chip>
                        <v-chip small v-if="item.companies && item.companies[0]"
                            :color="inRelatedFilter('companyId', item.companies[0].id) ? 'primary' : null"><v-icon small
                                left>mdi-office-building-outline</v-icon>{{
                                    item.companies[0].name
                                }}</v-chip>
                        <v-chip small v-if="item.services && item.services[0]"
                            :color="inRelatedFilter('serviceId', item.services[0].id) ? 'primary' : null"><v-icon small
                                left>mdi-file-sign</v-icon>{{ item.services[0].name }}</v-chip>
                        <v-chip small v-if="item.users && item.users[0]"
                            :color="inRelatedFilter('userId', item.users[0].id) ? 'primary' : null"><v-icon small
                                left>mdi-account-outline</v-icon>{{
                                    item.users[0].id ? item.users[0].getFullName() :
                                    'Onbekend'
                                }}</v-chip>
                    </v-chip-group>
            </template>
            <template v-slot:item.title="{item}">
                <v-card flat style="line-height: 1em;" color="transparent" @click="editTemplate(item)">
                    <h4>{{ item.measurementTypes[0] ? item.measurementTypes[0].name : 'Alarm' }}</h4>
                    <small v-if="item.products[0] && item.products[0].name">{{ item.products[0].name }}</small>
                </v-card>
                
            </template>
            <template v-slot:item.settings="{item}">
                <eod-alarm-settings :value="item"></eod-alarm-settings>
            </template>
            <template v-slot:item.toggle_time="{item}">
                {{ item.content.toggle_time?item.content.toggle_time+' min':'/'}}
            </template>
            <template v-slot:item.interval="{item}">
                {{ item.content.interval/(item.content.interval_divider || 1000) }} {{(item.content.interval_divider||1000) == 60000?'min':'s'}}
            </template>
            <template v-slot:item.actions="{item}">
                <div class="d-flex">
                <v-switch hide-details class="mt-0 float-right" v-model="item.isActive" inset
                        @change="saveTemplate(item)" color="success"></v-switch>
                <v-btn fab x-small depressed class="mr-2"
                        @click="duplicateTemplate(item)"><v-icon>mdi-content-duplicate</v-icon></v-btn>
                <v-btn fab x-small depressed class="mr-2"
                        @click="editTemplate(item)"><v-icon>mdi-pencil-outline</v-icon></v-btn>
                <v-btn fab color="error" x-small depressed
                        @click="deleteTemplate(item)"><v-icon>mdi-trash-can-outline</v-icon></v-btn>
                    </div>
            </template>
        </v-data-table>
        <eod-dialog v-if="addTemplateShown" v-model="addTemplateShown" title="Alarm toevoegen" width="1600"
            icon="mdi-bell-alert-outline" iconColor="primary" okText="Opslaan" @okAction="addTemplate">
            <eod-alarm-detail v-model="newTemplate" :defaultFilters="filters" :filtersReadonly="filtersReadonly"></eod-alarm-detail>
        </eod-dialog>
        <eod-delete-dialog v-if="deleteTemplateShown" v-model="deleteTemplateShown"
            :item="deleteTemplateItem.measurementTypes[0] ? deleteTemplateItem.measurementTypes[0] : { name: 'Alarm' }"
            @delete="deleteTemplateAction()">
        </eod-delete-dialog>
    </div>
</template>
<script>
import eodDialog from './eod-dialog.vue';
import eodAlarmDetail from './eod-alarm-detail.vue';
import eodAlarmSettings from './eod-alarm-settings.vue';
import eodDeleteDialog from './eod-delete-dialog.vue';
import { User, AlarmRule, Template } from '../..';
import _ from 'lodash';

export default {
    name: 'eod-alarm-list',
    components: {
        eodDialog,
        eodAlarmDetail,
        eodAlarmSettings,
        eodDeleteDialog,
    },
    props: {
        filters: {
            type: Object,
            default: () => ({})
        },
        createOnEmpty: {
            type: Boolean,
            default: false
        },
        filtersReadonly: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            rules: {
                required: (value, fieldName) => !!value || (fieldName ? fieldName : 'Veld') + ' is verplicht.',
            },
            init: false,
            templates: [],
            usersById: {},
            addTemplateShown: false,
            deleteTemplateShown: false,
            deleteTemplateItem: null,
            newTemplate: null,
            AlarmRule: AlarmRule,
            headers:[
                {
                    text: 'Meetwaarde/Asset',
                    value: 'title',
                    sortable: false
                },
                {
                    text: 'Instelling',
                    value: 'settings',
                    sortable: false
                },
                {
                    text: 'Tags',
                    value: 'tags',
                    sortable: false
                },
                {
                    text: 'Interval',
                    width:50,
                    value: 'interval',
                    sortable: false
                },
                {
                    text: 'Controletijd',
                    width:50,
                    value: 'toggle_time',
                    sortable: false
                },
                {
                    text: '',
                    width:50,
                    value: 'actions',
                    sortable: false
                }
            ]
        }
    },
    mounted() {
        this.loadTemplates();
    },
    watch: {
        filters() {
            this.loadTemplates();
        }
    },
    methods: {
        editTemplate(template){
            this.newTemplate = template;
            this.addTemplateShown = true;
        },
        deleteTemplate(template){
            this.deleteTemplateItem = template;
            this.deleteTemplateShown = true;
        },
        addTemplate() {
            this.saveTemplate(this.newTemplate).then(() => {
                this.newTemplate = null;
                this.addTemplateShown = false;
            });
        },
        inRelatedFilter(idField, id) {
            if (this.filters && this.filters.whereInRelated) {
                for (let i = 0; i < this.filters.whereInRelated.length; i++) {
                    const relationFilter = this.filters.whereInRelated[i];
                    if (relationFilter.column == idField) {
                        if (relationFilter.array.includes(id)) {
                            return true;
                        }
                    }
                }
            }

            return false;
        },
        saveTemplate(template) {
            return this.$eod.saveAlarmRule(template)
            .then(() => {
                return this.loadTemplates();
            })
        },
        duplicateTemplate(template){
            const newTemplate = _.cloneDeep(template);
            delete newTemplate.id;
            newTemplate.externalIds = [];
            this.saveTemplate(newTemplate);
        },
        deleteTemplateAction() {
            this.$eod.delete('Template', this.deleteTemplateItem.id).then(() => {
                this.loadTemplates();
            });
        },
        loadTemplates() {

            const filter = { ...this.filters };
            if (!filter.where) {
                filter.where = [];
            }

            if(!filter.isActive){
                filter.isActive = false;
            }

            filter.where.push({
                column: 'type',
                operator: '=',
                value: 'ALARM_RULE'
            });

            this.$eod.get('templates', ['id', 'name', 'isActive', 'type', 'object', 'externalIds{name id}', 'content', 'measurementTypes{id name unit{id name fields{name description} list{fields{name}}} valueConfigList{id name description itemType fields{name description isActive} listItems{id name description low high threshold stepSize color fields{name description isActive}}} valueConfigListItems{id name description low high threshold stepSize color fields{name description isActive}}} products{id name}', 'projects{id name}', 'companies{id name}', 'jobTypes{id name}', 'services{id name}', 'users{id username}'], filter).then(async response => {
                // Users need resolving
                const templates = await this.resolveUsers(response.data.data.templates.edges);
                this.templates = templates;
                if(!this.init && this.createOnEmpty && (!this.templates || templates.length == 0)){
                    this.editTemplate(null);
                    this.init = true;
                }
            });
        },
        async resolveUsers(templates) {
            const userIds = [];

            for (let i = 0; i < templates.length; i++) {
                const template = templates[i];
                if (template.users && template.users[0]) {
                    for (let j = 0; j < template.users.length; j++) {
                        const user = template.users[j];
                        if (!userIds.includes(user.id) && !this.usersById[user.id]) {
                            userIds.push(user.id);
                        }
                    }
                }
            }

            if (userIds[0]) {
                await this.$eod.get('resolveUsers', ['id', 'username', 'firstName', 'lastName'], {
                    whereIn: {
                        column: 'id',
                        array: userIds
                    }
                }).then(response => {
                    for (let i = 0; i < response.data.data.resolveUsers.edges.length; i++) {
                        const user = response.data.data.resolveUsers.edges[i];
                        this.usersById[user.id] = new User(user);
                    }
                    this.$forceUpdate();
                });
            }

            for (let i = 0; i < templates.length; i++) {
                for (let j = 0; j < templates[i].users.length; j++) {
                    templates[i].users[j] = this.usersById[templates[i].users[j].id];
                }
            }

            return templates;
        },
    }
}
</script>