import Export from './../../../models/export.js';
import _ from 'lodash';

export default {
    data: () => ({
        exportTemplate:null,
        filters: [],
        isExportDialogVisible: false,
        isExportLoading:false,
    }),
    methods:{
        filterChanged(filters) {
            this.filters = filters;
            this.$nextTick(() => {
                this.updatePreview();
            })
        },
        saveExport(){
            this.isExportLoading = true;
            const data = this.exportTemplate.getSaveData();
            this.$eod.save('Template', data).then(() => {
                this.isExportDialogVisible = false;
                this.$toaster.notify({
                    type: 'success',
                    title: 'Succes',
                    body: 'Export is succesvol aangemaakt!'
                });
            }).finally(() => {
                this.isExportLoading = false;
            });
        },
        exportQuery(){
            if(this.saveQuery){
                this.saveQuery({callback: () => {
                    this.showExportDialog();
                }});
            } else {
                this.showExportDialog();
            }
            
        },
        showExportDialog(){
            this.exportTemplate = new Export({
                name: 'Export '+this.template.name,
                type: 'EXPORT',
                access: 'PUBLIC',
                isActive: true,
                content: {
                    source:this.template.id,
                    periodicity:null,
                    location:null,
                    format:'csv',
                    filters: [],
                    from: this.$moment().add(-1, 'months').utc().toISOString(),
                    until: this.$moment().utc().toISOString()
                }
            });
            this.isExportDialogVisible = true;
        },
        updatePreview() {
            if(this.$refs.table){
                this.$refs.table.reload();
            }
        },
    }
    
}