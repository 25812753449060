<template>
    <v-card v-bind="$attrs" v-on="$listeners">
        <slot name="header">
            <v-card-title v-if="icon || title || subtitle || $slots['header-icon'] || $slots['header-title'] || $slots['header-subtitle'] || $slots['header-actions']" class="text-body-1" :class="titleClass||'pb-2'">
                <slot name="header-icon"><v-icon small left>{{ icon }}</v-icon></slot>
                <div class="d-flex flex-column">
                    <slot name="header-title"><div>{{ title }}</div></slot>
                    <slot name="header-subtitle"><div class="text-caption">{{ subtitle }}</div></slot>
                </div>
            <v-spacer></v-spacer>
            <slot name="header-actions"></slot>
            </v-card-title>
        </slot>
        <slot name="default">
        </slot>
    </v-card>
</template>
<script>
export default {
    name: 'eod-card',
    props:{
        title: String,
        titleClass: String,
        subtitle: String,
        icon: String,
    }
}
</script>