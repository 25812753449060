<template>
    <eod-dialog :inset="false" :value="value" @input="val => $emit('input', val)" title="Tegel toevoegen" width="1400" icon="mdi-view-dashboard-outline" iconColor="primary">
    <v-stepper v-model="step" flat class="h-100 d-flex flex-column rounded-0">
    <v-stepper-header style="height:82px;">
      <v-stepper-step
        editable
        :complete="step > 1"
        step="1"
      >
        Visualisatie kiezen
        <small class="text-body-2" :class="{'opacity-4': step != 1}">Kies hoe je data wordt weergegeven</small>
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
        :editable="tile.typeId?true:false"
        :complete="step > 2"
        step="2"
        v-if="isStepNeeded(2)"
      >
      Data selecteren
      <small class="text-body-2" :class="{'opacity-4': step != 2}">Selecteer de databron en filters</small>
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :step="isStepNeeded(2)?3:2" :editable="tile.series && tile.series[0]?true:false">
        Instellingen configureren
        <small class="text-body-2" :class="{'opacity-4': step != 3}">Pas kleuren, labels en schaal aan</small>
      </v-stepper-step>
    </v-stepper-header>
    <v-divider></v-divider>
    <v-stepper-items class="flex-grow-1" style="overflow-y:auto;">
      <v-stepper-content step="1">
        <v-item-group v-model="tile.typeId" @change="setTileType">
        <div v-for="group in cellGroups" :key="group.key" class="mb-4">
            <h2 class="text-h6">{{ group.title }}</h2>
            <v-row>
                <v-col cols="12" md="6" lg="3" v-for="type in group.cellTypes" :key="type.key">
                    <v-item v-slot="{ active, toggle }"  :value="type.key">
                    <eod-card :color="active?'secondary':null" @click="toggle" outlined>
                        <template v-slot:header>
                            <v-card-title>
                            <v-avatar size="40"  class="mr-2">
                                <eod-icon :class="{'primary--text':!active, 'white--text':active}" size="30">{{ type.icon }}</eod-icon>
                            </v-avatar>
                            <div class="text-body-1" :class="{'white--text':active}">
                                {{ type.title }}
                            </div>
                            <v-spacer></v-spacer>
                            <v-tooltip top v-if="type.info">
                                <template v-slot:activator="{attrs, on}">
                                    <v-btn :dark="active" icon v-bind="attrs" v-on="on"><v-icon>mdi-information-outline</v-icon></v-btn>
                                </template>
                                {{ type.info }}
                            </v-tooltip>
                        </v-card-title>
                        </template>
                    </eod-card>
                </v-item>
                </v-col>
        </v-row>
        </div>
    </v-item-group>
      </v-stepper-content>

      <v-stepper-content v-if="isStepNeeded(2)" step="2">
        <v-row v-if="tile.typeId">
            <v-col cols="12" lg="6">
                <eod-card title="Welke gegevens moeten worden weergegeven?" outlined>
                    <v-card-text>
                        <div class="d-flex align-center">
                <eod-object-autocomplete :disabled="tileReachedMaxSeries()" :value="null" @input="addSerie" return-object :defaultFilter="{whereIn:[{column:'type',array:['QUERY']}]}" placeholder="Kies een bestaande query" endpoint="templates" clearable>
                </eod-object-autocomplete>
                <v-tooltip top :disabled="!tileReachedMaxSeries()">
                    <template v-slot:activator="{attrs, on}">
                        <div v-bind="attrs" v-on="on">
                        <eod-btn :disabled="tileReachedMaxSeries()" @click="createQuery" class="ml-4" small><v-icon small left>mdi-plus</v-icon>Nieuwe query</eod-btn>
                    </div>
                    </template>
                    Maximum aantal queries voor deze visualisatie bereikt
                </v-tooltip>
                
            </div>
                <eod-expansion-panels class="mt-4" v-model="openSeriePanel">
                    <v-expansion-panel v-for="(serie, index) in tile.series" :key="index">
                        <v-expansion-panel-header>
                            <div class="d-flex align-center">
                            {{serie.label}}<v-spacer></v-spacer><v-btn small icon color="error"><v-icon small @click.stop="deleteSerie(index)">mdi-trash-can-outline</v-icon></v-btn>
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <eod-text-field class="mb-4" label="Label" v-model="serie.label"></eod-text-field>
                            <div class="d-flex">
                                <eod-object-autocomplete return-object @input="val => queryTemplateLoaded(index, val)" :fields="['id','name','content']" label="Query" v-if="typeof serie.query == 'string'" :value="serie.query" :defaultFilter="{whereIn:[{column:'type',array:['QUERY']}]}" endpoint="templates"></eod-object-autocomplete>
                                <v-tooltip top>
                                    <template v-slot:activator="{attrs, on}">
                                        <v-btn @click="editQuery(serie.query)" v-bind="attrs" v-on="on" icon small class="ml-2"><v-icon small>mdi-pencil-outline</v-icon></v-btn>
                                    </template>
                                    Query bewerken
                                </v-tooltip>
                            </div>
                            <eod-query-fixed-filters v-if="queryTemplates[serie.query] && queryTemplates[serie.query].content.source" v-model="serie.filters" :variables="configVariables" :fields="QUERY_SOURCES[queryTemplates[serie.query].content.source].fields"></eod-query-fixed-filters>
                            <eod-btn @click="previewSerie(serie)" block color="secondary"><v-icon left>mdi-lightning-bolt</v-icon>Query uitvoeren</eod-btn>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </eod-expansion-panels>
            </v-card-text>
            </eod-card>
            </v-col>
            <v-col cols="12" lg="6">
                <eod-card outlined>
                    <template v-slot:header>
                        <v-card-title class="text-body-1">
                            Resultaten controleren <v-spacer></v-spacer><v-chip small v-if="activeSerie">{{ activeSerie.label }}</v-chip>
                        </v-card-title>
                        <v-card-subtitle class="text-caption">
                            Controleer of de gegevens correct zijn
                        </v-card-subtitle>
                    </template>
                    <eod-graph-table flat ref="table" :filters="filters" :from="timeFilter.from" :until="timeFilter.until" hide-toolbar :value="activeSerie?({...tile, ...{graph:{fields:queryTemplates[activeSerie.query].content.fields.map(item => ({...item, ...{fieldKey:item.key, key: item.aggregation?item.aggregation+'_'+item.key:item.key}}))},series:[activeSerie]}}):null"></eod-graph-table>
                </eod-card>
            </v-col>
        </v-row>
        <eod-dialog width="1200" v-model="isCreateQueryDialogVisible" v-if="isCreateQueryDialogVisible" okText="Opslaan" @okAction="newQueryTemplateAction" title="Query aanmaken" icon="mdi-database-outline" iconColor="primary" :loading="isCreateQueryDialogLoading">
            <v-form ref="newQueryTemplateForm">
            <v-row class="mt-4">
                    <v-col>
                        <eod-card outlined>
                            <v-card-text>
                        <v-row class="mb-0">
                                            <v-col cols="auto">
                                                <v-chip color="grey lighten-3" label>Naam*</v-chip>
                                            </v-col>
                                            <v-col>
                                                <eod-text-field label="Naam" v-model="newQueryTemplate.name" required :rules="[val => $validator.required(val, 'Naam')]"></eod-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-divider></v-divider>
                        <eod-query-builder v-model="newQueryTemplate.content" @input="newQueryValidate"></eod-query-builder>
                        <v-alert type="error" text v-if="newQueryErrors[0]" dense>
                                            <ul>
                                                <li v-for="error in newQueryErrors" :key="error">{{ error }}</li>
                                            </ul>
                                        </v-alert>
                                        <v-btn color="secondary" :disabled="newQueryErrors[0]?true:false" rounded depressed block @click="newQueryExecute"><v-icon left>mdi-lightning-bolt-outline</v-icon>Query uitvoeren</v-btn>
                                    </v-card-text>
                                    </eod-card>
                    </v-col>
                    <v-col cols="12" md="6">
                        <eod-card outlined>
                            <v-card-text>
                                <eod-graph-table :filters="filters" :from="timeFilter.from" :until="timeFilter.until" flat ref="newQueryPreviewTable" hide-toolbar :value="newQueryPreviewTemplate"></eod-graph-table>
                            </v-card-text>
                        </eod-card>
                    </v-col>
                </v-row>
            </v-form>
        </eod-dialog>
      </v-stepper-content>

      <v-stepper-content :step="3">
        <v-row v-if="tile.typeId && step == 3">
            <v-col cols="12" lg="6">
                <eod-card title="Instellingen" outlined>
                    <v-card-text>
                        <component :variables="configVariables" :is="CELL_TYPES[tile.typeId].configurationComponent" v-model="tile" @input="$refs.configPreview?$refs.configPreview.update():null"></component>
                    </v-card-text>
                </eod-card>
            </v-col>
            <v-col cols="12" lg="6">
                <eod-card title="Voorbeeld" outlined>
                    <v-card-text class="flex-grow-1">
                        <div class="w-100 position-relative" :style="'padding-bottom:'+(tile.h/(tile.w*2.5))*100+'%;'">
                            <div class="position-absolute w-100 h-100 d-flex flex-column">
                                <component hide-toolbar :filters="filters" :from="timeFilter.from" :until="timeFilter.until" :variables="variables" flat :is="CELL_TYPES[tile.typeId].viewComponent" :value="tile" ref="configPreview"></component>
                            </div>
                        </div>
                    </v-card-text>
                </eod-card>
            </v-col>
        </v-row>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
  <template v-slot:actions>
    <eod-btn @click="$emit('input', false)" color="default" text>Annuleren</eod-btn>
    <v-spacer></v-spacer>
    <eod-btn @click="$emit('save', tile)" v-if="step == 3">
        <template v-if="tile.i"><v-icon left>mdi-content-save</v-icon>Opslaan</template>
        <template v-else><v-icon left>mdi-plus</v-icon>Toevoegen</template>
    </eod-btn>
    <eod-btn v-else :disabled="!tile.typeId" @click="gotoNextStep">Volgende<v-icon right>mdi-chevron-right</v-icon></eod-btn>
    
  </template>
</eod-dialog>
</template>
<script>
import {CELL_GROUPS, CELL_TYPES} from './../../../models/dashboard.js';
import { QUERY_SOURCES, validateQueryTemplate } from '../../../plugins/queryeditor/config.js';
import eodQueryBuilder from '../../eod-query-builder.vue';
import eodObjectAutocomplete from '../../eod-object-autocomplete.vue';
import eodQueryFixedFilters from '../../eod-query-fixed-filters.vue';
import eodGraphTable from '../eod-graph-table.vue';
import _ from 'lodash';

export default {
    name: 'eod-create-tile-dialog',
    components: {
        eodQueryBuilder,
        eodObjectAutocomplete,
        eodQueryFixedFilters,
        eodGraphTable
    },
    props:{
        value: Boolean,
        defaultTile: Object,
        variables: {
            type: Object,
            default: null
        },
        configVariables: {
            type: Object,
            default: null
        },
        timeFilter: {
            type: Object,
            default: null
        },
        filters: {
            type: Array,
            default: null
        },
    },
    data: () => ({
        QUERY_SOURCES: QUERY_SOURCES,
        CELL_TYPES: CELL_TYPES,
        cellGroups: Object.values(CELL_GROUPS).filter(item => item.cellTypes),
        queryTemplates:{},
        openSeriePanel: 0,
        step: 1,
        tile: {},
        activeSerie: null,
        isCreateQueryDialogVisible: false,
        isCreateQueryDialogLoading: false,
        newQueryTemplate: {},
        newQueryErrors: [],
        newQueryPreviewTemplate: {}
    }),
    mounted(){
        if(this.defaultTile){
            this.tile = _.merge(this.tile, this.defaultTile);

            if(this.tile.i){
                this.step = 3;
            }

            this.$forceUpdate();
        }
    },
    methods:{
        tileReachedMaxSeries(){
            if(!this.tile.typeId){
                return false;
            }

            if(!this.tile.series){
                return false;
            }

            const tileConfig = CELL_TYPES[this.tile.typeId].config;
            if(tileConfig && tileConfig.maxSeries){
                return this.tile.series.length >= tileConfig.maxSeries;
            }
        },
        newQueryTemplateAction(){
            const isValid = this.$refs.newQueryTemplateForm.validate();
            this.newQueryValidate();
            
            if(isValid && !this.newQueryErrors[0]){
                this.isCreateQueryDialogLoading = true;
                this.$eod.save('Template', this.newQueryTemplate).then(response => {
                    if(response.data.data){
                        if(response.data.data.createTemplate){
                            this.newQueryTemplate.id = response.data.data.createTemplate.id;
                            this.addSerie(this.newQueryTemplate);
                        }

                        this.queryTemplates[this.newQueryTemplate.id] = _.cloneDeep(this.newQueryTemplate);
                        this.isCreateQueryDialogVisible = false;
                    }
                }).finally(() => {
                    this.isCreateQueryDialogLoading = false;
                })
            }
        },
        newQueryExecute(){
            this.newQueryValidate();

            if(!this.newQueryErrors[0]){
                this.newQueryPreviewTemplate = {
                    graph:{
                        fields:this.newQueryTemplate.content.fields?this.newQueryTemplate.content.fields.map(item => ({...item, ...{fieldKey:item.key, key: item.aggregation?item.aggregation+'_'+item.key:item.key}})):[]
                    },
                    series:[{query:this.newQueryTemplate}]
                };

                this.$nextTick(() => {
                    if(this.$refs.newQueryPreviewTable){
                        this.$refs.newQueryPreviewTable.reload();
                    }
                });
            }
            
        },
        newQueryValidate(){
            this.newQueryErrors = validateQueryTemplate(this.newQueryTemplate);
        },
        editQuery(templateId){
            this.$eod.getById('template', templateId, ['id','name','type','access','isActive','content']).then(response => {
                if(response.data.data && response.data.data.template){
                    this.newQueryTemplate = response.data.data.template;
                    this.$nextTick(() => {
                        this.isCreateQueryDialogVisible = true;
                    });
                }
            })
        },
        createQuery(){
            this.newQueryTemplate = {
                name: null,
                type: 'QUERY',
                access: 'PUBLIC',
                isActive: true,
                content: {
                    source: 'measurements',
                    filters: []
                }
            };

            this.$nextTick(() => {
                this.isCreateQueryDialogVisible = true;
            });
            
        },
        deleteSerie(index){
            this.tile.series.splice(index, 1);
            this.$forceUpdate();
        },
        isStepNeeded(step){
            return !(this.tile.typeId && CELL_TYPES[this.tile.typeId].steps && !CELL_TYPES[this.tile.typeId].steps.includes(step));
        },
        setTileType(typeId){
            this.tile = {
                i: this.tile.i||null,
                typeId: typeId,
            };

            const typeOptions = _.cloneDeep(CELL_TYPES[this.tile.typeId]);
            if(typeOptions.defaultTile){
                this.tile = {
                    ...this.tile,
                    ...typeOptions.defaultTile
                };
            }

            if(this.defaultTile){
                this.tile = _.merge(this.tile, this.defaultTile);
            }

            this.$forceUpdate();
        },
        gotoNextStep(){
            let newStep = parseInt(this.step) + 1;
            while(!this.isStepNeeded(newStep)){
                newStep++;
            }

            this.step = newStep;
        },
        queryTemplateLoaded(index, template){
            this.tile.series[index].query = template.id;
            this.queryTemplates[template.id] = template;
            this.$forceUpdate();
        },
        previewSerie(serie){
            this.activeSerie = serie;
            this.$nextTick(() => {
                this.$refs.table.reload();
            });
        },
        addSerie(template){

            if(!template){
                return;
            }

            const serie = {
                label:null,
                filters: []
            }
            
            serie.label = template.name;
            serie.query = template.id;

            if(!this.tile.series){
                this.tile.series = [];
            }

            this.tile.series.push(serie);

            this.openSeriePanel = this.tile.series.length-1;

            this.$forceUpdate();
        }
    }
}
</script>