<template>
    <div>
        <v-navigation-drawer width="30%" v-model="expanded" absolute right elevation="10" class="elevation-10"
            style="z-index:2;max-width: 400px;" @input="navigationDrawerInput">
            <v-toolbar color="primary" dark dense>
                <v-toolbar-title>Event</v-toolbar-title>

                <v-spacer></v-spacer>

                <v-btn rounded color="light" outlined icon small @click="closeMe">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <div class="py-4 px-4">
                <table class="table table-striped w-100" v-if="event">
                    <tr>
                        <th class="text-left">Tijd</th>
                        <td class="text-left">{{ $moment(event.time).format('DD/MM HH:mm') }}</td>
                    </tr>
                    <tr>
                        <th class="text-left">Meetwaarde</th>
                        <td class="text-left">{{ event.measurementType ? event.measurementType.name : '' }}</td>
                    </tr>
                    <tr>
                        <th class="text-left">Waarde</th>
                        <td class="text-left">{{ event.value }}</td>
                    </tr>
                    <tr>
                        <th class="text-left">Bericht</th>
                        <td class="text-left">{{ event.message }}</td>
                    </tr>
                    <tr>
                        <th class="text-left">Type</th>
                        <td class="text-left">
                            <v-icon left size="18" :title="event.type">{{
                                Event.getType(event.type) ? Event.getType(event.type).icon : ''
                            }}</v-icon>{{ event.type }}
                        </td>
                    </tr>
                    <tr>
                        <th class="text-left">Severity</th>
                        <td class="text-left">
                            <v-chip small
                                :color="Event.getSeverity(event.severity) ? Event.getSeverity(event.severity).color : ''"
                                dark>
                                {{ event.severity }}
                            </v-chip>
                        </td>
                    </tr>
                    <tr>
                        <th class="text-left">Status</th>
                        <td class="text-left">
                            <v-icon left size="18" :title="event.status">{{
                                Event.getStatus(event.status) ? Event.getStatus(event.status) : ''
                            }}</v-icon>{{ event.status }}
                        </td>
                    </tr>
                    <tr>
                        <th class="text-left">Databron</th>
                        <td class="text-left">
                            <v-chip small>
                                <v-icon left size="18">mdi-account-outline</v-icon>{{
                                    event.owner ? event.owner.name : ''
                                }}
                            </v-chip>
                        </td>
                    </tr>
                    <!--<tr>
                            <th class="text-left">Afbeeldingen</th>
                            <td class="text-left">{{ event.storageObjects ? event.storageObjects.count : 0 }}</td>
                        </tr>-->
                    <tr>
                        <th class="text-left">Asset</th>
                        <td class="text-left">{{ event.device }}</td>
                    </tr>
                    <tr>
                        <th class="text-left">Project</th>
                        <td class="text-left"><v-icon left size="18">mdi-folder-open-outline</v-icon>{{
                            event.project ? event.project.name : ''
                        }}
                        </td>
                    </tr>
                    <tr>
                        <th class="text-left">Bedrijf</th>
                        <td class="text-left"><v-icon left size="18">mdi-office-building-outline</v-icon>{{
                            event.company ? event.company.name : ''
                        }}</td>
                    </tr>
                    <tr>
                        <th class="text-left">Categorie</th>
                        <td class="text-left">{{ event.category }}</td>
                    </tr>
                    <!--<tr>
                            <th class="text-left">Materialen</th>
                            <td class="text-left">
                                <v-chip small>
                                    <v-icon left size="18">mdi-water-pump</v-icon>{{
                                        event.products ?
                                            event.products.length : 0
                                    }}
                                </v-chip>
                            </td>
                        </tr>-->
                    <!--<tr>
                        <th colspan="2" class="text-left"><v-icon left
                                size="18">mdi-comment-processing-outline</v-icon>Opmerkingen</th>
                    </tr>-->
                    <tr v-if="event.comments && event.comments.length > 0">
                        <td colspan="2" class="text-left">
                            <eod-comments v-model="event.comments" readonly flat dense
                                image-field-name="storageObjects"></eod-comments>
                        </td>
                    </tr>
                    <!--<tr v-for="comment in event.comments" :key="comment.createdAt">
                        <td colspan="2" class="text-left"><strong>{{
                            $moment(comment.createdAt).format('YYYY-MM-DD HH:mm:ss')
                        }}</strong><br>
                            {{ comment.content }}
                        </td>
                    </tr>-->
                </table>

                <!--<v-row class="mt-5" justify="center">
                        <v-col cols="auto">
                            <v-btn small color="secondary" elevation="2" title="Commentaar toevoegen">
                                <v-icon size="18">mdi-comment-plus</v-icon>
                            </v-btn>
                        </v-col>

                        <v-col cols="auto">
                            <v-btn small color="secondary" elevation="2" title="Foto toevoegen">
                                <v-icon size="18">mdi-image-plus</v-icon>
                            </v-btn>
                        </v-col>

                        <v-col cols="auto">
                            <v-btn small color="secondary" elevation="2" title="Maskeren">
                                <v-icon size="18">mdi-domino-mask</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>-->
            </div>
        </v-navigation-drawer>
    </div>
</template>

<script>
//  import eodEventsDetail from './eod-events-detail';
import Event from './../models/event';
import User from './../models/user';
import eodComments from './eod-comments';

export default {
    name: 'eod-events-sidebar',

    components: {
        //eodEventsDetail
        eodComments
    },

    props: ['event'],

    data: () => ({
        expanded: false,
        Event: Event,
        User: User,
    }),
    watch: {
        event: {
            deep: true,
            immediate: true,
            handler(oldEvent, newEvent) {
                this.expand(oldEvent ? true : false);
            }
        },
    },

    methods: {
        expand(expand) {
            this.expanded = expand ?? true;
        },
        closeMe(event) {
            this.expanded = false;
            this.$emit('closed');
        },

        navigationDrawerInput(expanded) {
            if (!expanded) {
                this.$emit('closed');
            }
        }
    },
}
</script>


<style lang="scss">
// Table custom style
.table {
    th {
        padding-right: 10px;
        color: rgba(#000, .7);
    }

    th,
    td {
        padding-bottom: 10px;
        vertical-align: top;
    }
}
</style>