<template>
    <eod-graph v-bind="{...$props, ...$attrs}" v-on="$listeners" @reload="reload" :alarms="alarms" :series="series" @exportData="exportData" :loading="loading">
        <div class="flex-grow-1 overflow-hidden">
        <v-data-table :items="data" :headers="headers" fixed-header v-if="!loading" :custom-sort="customSort" :options="tableOptions">
            <template v-for="header in headers" v-slot:[header.slotName]="{ item, header, value }">
                <td>
                    <component :key="value" ref="field" :value="item[header.value]" :options="header.options" :fieldName="header.key" :data="item" :queryTemplate="queryTemplates?queryTemplates[header.serie||0]:null" :filters="filters" :from="from" :until="until" :config="header.config" :is="header.view.component" v-bind="header.view.attrs">
                        {{ item[header.value] }}
                    </component>
                </td>
            </template>
        </v-data-table>
    </div>
    </eod-graph>
</template>
<script>
import { QUERY_FIELD_VISUALIZATIONS } from './../../plugins/queryeditor/config.js';
import graphMixin from './mixins/graph.js';

export default {
    name: 'eod-graph-table',
    mixins:[graphMixin],
    data: () => ({
        headers: [],
        data: [],
        tableOptions: {
            sortBy: [],
            sortDesc: []
        }
    }),
    mounted(){
        this.reload();
    },
    methods: {
        reload(){
            this.loadData().then(() => {
                this.update();
            });
        },
        update(){
            this.initHeaders();
            this.initData();

            this.$nextTick(() => {
                if(this.$refs.field){
                    for (let i = 0; i < this.$refs.field.length; i++) {
                        const fieldEl = this.$refs.field[i];
                        if(fieldEl.update){
                            fieldEl.update();
                        }
                    }
                }
            });
        },
        initData(){
            this.data = [];

            if(!this.value || !this.series[0]){
                return;
            }

            const mergedData = {};

            const groupFields = this.value.graph.fields.filter(item => item.serie == null).map(item => item.key);
            for (let i = 0; i < this.series.length; i++) {
                const serieData = this.series[i];
                for (let dataIndex = 0; dataIndex < serieData.length; dataIndex++) {
                    const row = serieData[dataIndex];
                    let unique = '';

                    let combineFields = [];
                    if(this.value.graph.combineFields){
                        combineFields = this.value.graph.combineFields;
                    } else {
                        combineFields = groupFields;
                    }

                    for (let groupIndex = 0; groupIndex < combineFields.length; groupIndex++) {
                        const field = combineFields[groupIndex];
                        unique += '_' + row[field];
                    }

                    if(!mergedData[unique]){
                        mergedData[unique] = row;
                    }

                    for (let fieldIndex = 0; fieldIndex < this.value.graph.fields.length; fieldIndex++) {
                        const field = this.value.graph.fields[fieldIndex];
                        const fieldConfig = this.getFieldByKey(field.fieldKey);
                        if(fieldConfig){
                            const view = field.view?this.$helper.getArrayItemByProperty(fieldConfig.views, 'key', field.view):fieldConfig.views[0];
                            if(view.sortResolved){
                                const resolvedItem = this.$resolver.get(fieldConfig.endpoint, row[field.key]);
                                if(resolvedItem){
                                    if(field.serie == i){
                                        row[field.serie+'_'+field.key+'_sort'] = resolvedItem.name;
                                    } else {
                                        row[field.key+'_sort'] = resolvedItem.name;
                                    }
                                }
                            }
                        }
                        
                        if(field.serie == i){
                            mergedData[unique][field.serie+'_'+field.key] = row[field.key];
                        }
                    }
                }
            }

            const data = Object.values(mergedData);

            if(data){
                this.data = data;
            }

            this.$forceUpdate();
        },
        initHeaders(){
            this.headers = [];

            if(this.value && this.value.graph && this.value.graph.fields){
                for (let i = 0; i < this.value.graph.fields.length; i++) {
                    const field = this.value.graph.fields[i];

                    if(!field.hasOwnProperty('visible') || field.visible){
                        const fieldConfig = this.getFieldByKey(field.fieldKey);

                        

                        if(fieldConfig){

                            const view = field.view?this.$helper.getArrayItemByProperty(fieldConfig.views, 'key', field.view):fieldConfig.views[0];

                            this.headers.push({
                                value: (field.serie!=null?field.serie+'_'+field.key:field.key),
                                text: field.label?field.label:fieldConfig.text,
                                config: fieldConfig,
                                options: field.options,
                                serie: field.serie,
                                key: field.key,
                                view: view,
                                sortable: view?view.sortable:true,
                                slotName: 'item.' + (field.serie!=null?field.serie+'_'+field.key:field.key),
                            });
                        } else {
                            this.headers.push({
                                value: field.key,
                                text: field.key,
                                options: field.options,
                                serie: field.serie,
                                key: field.key,
                                view: QUERY_FIELD_VISUALIZATIONS.raw,
                                slotName: 'item.' + (field.serie!=null?field.serie+'_'+field.key:field.key),
                            });
                        }
                    }
                }
            }

            if(this.headers[0]){
                this.tableOptions.sortBy = [this.headers[0].value];
                this.tableOptions.sortDesc = [this.headers[0].view.defaultSortDesc];
            }

            this.$forceUpdate();
        },
        customSort(items, index, isDesc) {
            items.sort((a, b) => {
                const indexA = a[index+'_sort']?index+'_sort':index;
                const indexB = b[index+'_sort']?index+'_sort':index;

                if (a[indexA] === b[indexB]) { // equal items sort equally
                    return 0;
                } else if (a[indexA] === null) { // nulls sort after anything else
                    return isDesc[0]?1:-1;
                } else if (b[indexB] === null) {
                    return isDesc[0]?-1:1;
                } else if (!isDesc[0]) { // otherwise, if we're ascending, lowest sorts first
                    return a[indexA] < b[indexB] ? -1 : 1;
                } else { // if descending, highest sorts first
                    return a[indexA] < b[indexB] ? 1 : -1;
                }
            });

            return items;
        },

    }
}
</script>