<template>
    <div>
        
    
        <div class="d-flex align-center w-100 position-relative">
                <eod-text-field @focus="getFocus" :info="info" @input="colorChanged" autocomplete="off" prepend-icon="mdi-palette" :label="label" :value="value" clearable @click:clear="colorChanged('')"></eod-text-field>
                <input type="color" ref="colorPicker" @input="e => colorChanged(e.target.value)" :value="value" class="position-absolute" style="width:0;height:0;visibility: hidden;top:100%;left:0;">
                <v-avatar class="ml-2" size="20" :color="value"></v-avatar>
            </div>
</div>
</template>
<script>
export default {
    props: {
        value: String,
        info: String,
        label: String,
        dotSize: {
            type: String | Number,
            default: 15
        },
        hideModeSwitch: {
            type: Boolean,
            default: true,
        },
        mode: {
            type: String,
            default: 'hexa'
        }
    },
    methods: {
        getFocus(){
            this.$refs.colorPicker.click();
        },
        colorChanged(hexVal) {

            if(!hexVal){
                this.$emit('input', null);
                return;
            }

            this.$emit('input', hexVal);
        }
    }
}
</script>