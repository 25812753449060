<template>
    <eod-graph-line v-if="tile" ref="graph" :value="tile" :from="from" :until="until" flat></eod-graph-line>
<!-- <eod-graph v-if="evolutionTemplates" ref="graph" type="line" :template="evolutionTemplates" :fake-prediction="$eod.getOrganizationSetting('measurements.trendgraph.predictions')?$eod.getOrganizationSetting('measurements.trendgraph.predictions').value == '1':false" :predictionValue="predictionValue" @queueQuery="queueQuery" :from="from" :fields="['product','owner']" :until="until" :groupBy="['product_id','job_type_id','measurement_type_id']"></eod-graph> -->
</template>
<script>
import dashboardQueue from './../mixins/dashboardQueue';
import eodGraphLine from './graphs2/eod-graph-line.vue'

export default {
    name:'eod-evolution-graph',
    components:{
        eodGraphLine
    },
    props:{
        from: Object,
        until: Object,
        measurementTypeId: String,
        filters: Object,
        predictionValue: Number,
    },
    mixins: [dashboardQueue],
    data:() => {
        return {
            loading: false,
            evolutionTemplates: null,
            reloadThreshold: false,
            tile:null
        }
    },
    mounted(){
        this.loadData();
    },
    methods: {
        loadData(){
            if(this.reloadThreshold){
                return false;
            }

            this.reloadThreshold = true;
            this.tile = null;
            this.$nextTick(() => {
                this.tile = {
                    typeId: 'line',
                    
                    series: [
                        {
                            xAxis:{
                            field:{
                                key: 'time'
                            },
                            key: 'time'
                        },
                        yAxis:{
                            key: 'last_value',
                            field: {
                                key: 'value'
                            }
                        },
                        extraSeries:['product_id','job_type_id','measurement_type_id'],
                            query: this.getEvolutionTemplate()
                        }
                    ]
                }
            });

            setTimeout(() => {
                this.reloadThreshold = false;
            }, 1000);
        },
        gotQueueResult(queueItem, result) {
            if (this.$refs.graph) {
                this.$refs.graph.queueResult(queueItem.templateIndex, queueItem.template, result);
            }
        },
        getEvolutionTemplate() {
            const queryTemplate = {
                source: 'measurements',
                fields:[
                    {
                        key: 'time',
                        aggregation: null
                    },
                    {
                        key: 'value',
                        aggregation: 'last'
                    }
                ],
                group: ['time', 'product_id','job_type_id','measurement_type_id'],
                filters: [
                    {
                        field:{
                            key: 'measurement_type_id'
                        },
                        operator: '=',
                        value: this.measurementTypeId
                    }
                ]
            };

            if (this.filters && this.filters.projectId) {
                queryTemplate.filters.push({
                    field: {
                        key: 'project_id',

                    },
                    operator: '=',
                    value: this.filters.projectId
                });
            }

            if (this.filters && this.filters.jobTypeId) {
                queryTemplate.filters.push({
                    field: {
                        key: 'job_type_id',
                    },
                    operator: '=',
                    value: this.filters.jobTypeId
                });
            }

            if (this.filters && this.filters.productId) {
                queryTemplate.filters.push({
                    field: {
                        key: "product_id",

                    },
                    operator: '=',
                    value: this.filters.productId,
                });
            }

            return {content: queryTemplate};
        },
    }
}
</script>