<template>
    <v-expansion-panels v-on="$listeners" v-bind="{...$attrs, ...$props}" flat :class="{'eod-expansion-panels-no-inset': noInset, 'eod-expansion-panels-no-border': noBorder}">
        <slot name="default"></slot>
    </v-expansion-panels>
</template>
<style lang="css" scoped>
.v-expansion-panels>>>.v-expansion-panel {
    border: 1px solid #dddddd;
}
.v-expansion-panels>>>.v-expansion-panel .v-expansion-panel-header {
    background:#FAFAFA;
}
.v-expansion-panels>>>.v-expansion-panel .v-expansion-panel-content {
    padding-top:16px;
}

.v-expansion-panels.eod-expansion-panels-no-inset>>>.v-expansion-panel>.v-expansion-panel-content,
.v-expansion-panels.eod-expansion-panels-no-inset>>>.v-expansion-panel>.v-expansion-panel-content >.v-expansion-panel-content__wrap {
    padding: 0;
}

.v-expansion-panels.eod-expansion-panels-no-border>>>.v-expansion-panel {
    border: none;
}
</style>
<script>
export default {
    name: 'eod-expansion-panels',
    props:{
        noInset: Boolean,
        noBorder: Boolean
    }
}
</script>