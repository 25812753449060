<template>
    <div>
            <v-tabs v-model="activeTab" background-color="transparent" class="mb-4">
                <v-tab>Algemeen</v-tab>
                <v-tab>Weergave</v-tab>
                <v-tab>Data verwerking</v-tab>
            </v-tabs>
        <v-tabs-items :value="activeTab">
            <v-tab-item transition="transition-fade">
                <v-row>
                    <v-col cols="12" lg="6">
                <eod-card class="mb-4 pt-2">
                    <v-card-text>
                <v-row>
                    <v-col>
                        <eod-text-field translatable :value="value.name" @input="val => updateProperty({name:val})" :rules="[val => rules.required(val, 'Naam')]" label="Naam*" required></eod-text-field>
                    </v-col>
                    <v-col>
                        <eod-listitem-autocomplete outlined dense hide-details
                            v-if="$eod.getOrganizationSetting('measurementTypes.units.listId')"
                            :prependItems="[{ id: null, name: 'Geen' }]" v-model="value.unit" label="Eenheid"
                            :returnObject="true"
                            :listId="$eod.getOrganizationSetting('measurementTypes.units.listId').value"></eod-listitem-autocomplete>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <eod-textarea :value="value.description" @input="val => updateProperty({description:val})" label="Omschrijving" rows="3" translatable info="Extra informatie die op het formulier of dashboard kan worden weergegeven"></eod-textarea>
                    </v-col>
                </v-row>
                <v-row>
                        <v-col>
                            <eod-autocomplete :items="settings.fieldTypes" label="Type*" :value="itemType" @input="val => updateProperty({formConfig:{type:val}})" item-value="value" item-text="text" :rules="[val => rules.required(val, 'Type')]" @change="fieldTypeChanged">
                                <template v-slot:item="{item, on, attrs}">
                                    <v-list-item v-on="on" v-bind="attrs">
                                        <v-list-item-content>
                                            <v-list-item-title>{{ item.text }}</v-list-item-title>
                                            <v-list-item-subtitle>{{ item.info }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </eod-autocomplete>
                        </v-col>
                        <v-col v-if="listTypes.includes(itemType)">
                            <div class="d-flex align-center">
                                <eod-object-autocomplete hide-details label="Bestaande lijst" :value="value.valueConfigList" @input="changeListItems" endpoint="lists" return-object :fields="['id', 'name', 'itemType', 'listItems{id name, threshold}']"></eod-object-autocomplete>
                                <v-btn class="ml-2" small depressed color="primary" rounded @click="createNewList"><v-icon left small>mdi-plus</v-icon>Nieuwe lijst</v-btn>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row v-if="['number'].includes(itemType)">
                        <v-col><eod-text-field type="number" step="0.01" :value="value.typeConfig.min" @input="val => updateProperty({typeConfig:{min: val}})" label="Min" clearable></eod-text-field></v-col>
                        <v-col><eod-text-field type="number" step="0.01" :value="value.typeConfig.max" @input="val => updateProperty({typeConfig:{max: val}})" label="Max" clearable></eod-text-field></v-col>
                    </v-row>
                    <div v-if="listTypes.includes(itemType)" class="mt-4">
                        
                        <div v-if="this.listItems">
                            <v-list dense>
                                <v-list-item v-for="(item, index) in visibleListitems()" :key="'listItem_'+index">
                                    <v-chip small class="mr-2">{{ item.threshold }}</v-chip>
                                    <v-list-item-content class="py-1">
                                        <eod-text-field v-model="item.name"></eod-text-field>
                                    </v-list-item-content>
                                    <v-btn @click="deleteListItem(index)" icon color="error"><v-icon>mdi-trash-can-outline</v-icon></v-btn>
                                </v-list-item>
                            </v-list>
                            <v-btn @click="addListItem" color="primary" dense rounded small class="mt-4"><v-icon small>mdi-plus</v-icon>Optie toevoegen</v-btn>
                        </div>
                    </div>
            </v-card-text>
        </eod-card>
    </v-col>
        <v-col cols="12" lg="6">
            <eod-card title="Formulier">
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="6" xl="4">
                            <v-card outlined>
                                <v-card-text>
                                    <v-switch class="mt-0" hide-details v-model="value.formConfig.enabled"
                                        label="Zichtbaar"></v-switch>
                                </v-card-text>
                                <div v-if="value.formConfig.enabled" class="grey lighten-4 px-4 py-2">
                                    <v-switch hide-details class="mt-0" v-model="value.formConfig.required" label="Verplicht"></v-switch>
                                </div>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="6" xl="4" v-if="value.formConfig.enabled">
                            <v-card outlined>
                                <v-card-text>
                                    <v-switch class="mt-0" hide-details v-model="value.formConfig.photo"
                                        label="Foto toevoegen mogelijk"></v-switch>
                                </v-card-text>

                                <div v-if="value.formConfig.photo" class="grey lighten-4 px-4 py-2">
                                    <v-switch hide-details class="mt-0" v-model="value.formConfig.photoRequired"
                                        label="Foto toevoegen verplicht"></v-switch>
                                </div>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="12" xl="4" v-if="value.formConfig.enabled">
                            <v-card outlined>
                                <v-card-text>
                                    <v-switch class="mt-0" hide-details v-model="value.formConfig.comment"
                                        label="Commentaar toevoegen mogelijk"></v-switch>
                                </v-card-text>

                                <div v-if="value.formConfig.comment" class="grey lighten-4 px-4 py-2">
                                    <v-switch hide-details class="mt-0" v-model="value.formConfig.commentRequired"
                                        label="Commentaar toevoegen verplicht"></v-switch>
                                </div>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
            </eod-card>
        </v-col>
        </v-row>
            </v-tab-item>
            <v-tab-item transition="transition-fade">
                <p>
                    <v-icon left>mdi-information</v-icon>Standaard weergave om automatisch een dashboard te genereren.
                </p>
                <eod-card>
                    <v-card-text>
                <v-row>
                    <v-col>
                        <eod-color-picker info="Standaard kleur om deze meetwaarde te visualiseren, merk op dat dit kan overschreven worden door de kleur ingesteld op een asset of dashboard tegel" label="Kleur" v-model="value.color" dot-size="15" hide-mode-switch mode="hexa"></eod-color-picker>
                    </v-col>
                    <v-col>
                        <eod-icon-picker info="Icoon om de meetwaarde weer te geven op een dashboard tegel" dense outlined label="Icoon" v-model="value.icon"></eod-icon-picker>
                    </v-col>
                </v-row>
                <v-item-group v-model="value.dashboardConfig.graphs" multiple>
            <v-row class="mt-4">
                <v-col cols="12" md="6" lg="3" xl="2" v-for="type in cellTypes" :key="type.key">
                    <v-item v-slot="{ active, toggle }"  :value="type.key">
                    <eod-card :color="active?'secondary':null" @click="toggle" outlined>
                        <template v-slot:header>
                            <v-card-title class="py-2">
                            <v-avatar size="40"  class="mr-2">
                                <eod-icon :class="{'primary--text':!active, 'white--text':active}" size="30">{{ type.icon }}</eod-icon>
                            </v-avatar>
                            <div class="text-body-1" :class="{'white--text':active}">
                                {{ type.title }}
                            </div>
                            <v-spacer></v-spacer>
                            <v-tooltip top v-if="type.info">
                                <template v-slot:activator="{attrs, on}">
                                    <v-btn :dark="active" icon v-bind="attrs" v-on="on"><v-icon>mdi-information-outline</v-icon></v-btn>
                                </template>
                                {{ type.info }}
                            </v-tooltip>
                        </v-card-title>
                        </template>
                        
                    </eod-card>
                </v-item>
                <eod-card v-if="value.dashboardConfig.graphs.includes(type.key) && type.key == 'tile'" outlined class="grey lighten-4 px-4 py-1">
                    <v-switch class="mt-0" hide-details v-model="value.isWritable" :true-value="true" :false-value="false" label="Meetwaarde kan worden ingesteld via het dashboard"></v-switch>
                </eod-card>
            </v-col>
        </v-row>
    </v-item-group>
            </v-card-text>
            </eod-card>
            </v-tab-item>
            <v-tab-item transition="transition-fade">
                <p>
                    <v-icon left>mdi-information</v-icon>Instellingen om de data die via een IoT gateway wordt ontvangen te verwerken. Merk op dat dit de standaard instellingen voor deze meetwaarde zijn, deze kunnen eventueel per dataconnector worden overschreven.
                </p>
                <eod-card>
                    <v-card-text>
                <v-row>
                    <v-col>
                        <eod-text-field info="Naam waarmee de data zal worden verstuurd door de IoT gateway naar de EoD broker vb. water_level_counter_1" outlined dense hide-details label="HardwareId" :value="getMetricValue('hardwareId')"
                            @input="val => setMetricValue('hardwareId', val)"></eod-text-field>
                    </v-col>
                    <!-- <v-col>
                        <v-autocomplete outlined dense hide-details label="dataType"
                            :items="MeasurementType.Metric_DataTypes" :value="getMetricValue('dataType')"
                            @input="val => setMetricValue('dataType', val)"></v-autocomplete>
                    </v-col> -->
                </v-row>
                <v-row>
                    <v-col><eod-text-field info="Een getal waarmee de ontvangen waarde dient vermenigvuldigd te worden voor deze in EoD wordt verwerkt vb. 0.1 zal de ontvangen waarde 10 omzetten naar 1, 100 zal de ontvangen waarde 10 omzetten naar 1000" type="number" step="0.1" outlined dense hide-details label="Factor"
                            :value="getMetricValue('factor')"
                            @input="val => setMetricValue('factor', val)"></eod-text-field></v-col>
                    <v-col><eod-text-field info="Een getal waarmee de ontvangen waarde dient opgeteld te worden voor deze in EoD wordt verwerkt om bijvoorbeeld de beginstand van een teller in rekening te brengen vb. 50 zal de ontvangen waarde 5 omzetten naar waarde 55, -100 zal de ontvangen waarde 2000 omzetten naar 1900" type="number" step="0.1" outlined dense hide-details label="Offset"
                            :value="getMetricValue('offset')"
                            @input="val => setMetricValue('offset', val)"></eod-text-field></v-col>
                    <v-col><eod-text-field info="Het data collectie interval in seconden" type="number" step="1" outlined dense hide-details label="Interval"
                            :value="getMetricValue('interval')"
                            @input="val => setMetricValue('interval', val)"></eod-text-field></v-col>
                </v-row>
                <v-row>
                    <v-col><eod-autocomplete outlined dense hide-details label="Methode"
                            :items="MeasurementType.Metric_Methods" :value="getMetricValue('method')"
                            @input="val => setMetricValue('method', val)">
                        <template v-slot:item="{item, on, attrs}">
                            <v-list-item v-bind="attrs" v-on="on">
                                <v-list-item-content>
                                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ item.info }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                        </eod-autocomplete></v-col>
                </v-row>
            </v-card-text>
            </eod-card>
            </v-tab-item>
            
        </v-tabs-items>
    </div>
</template>

<script>
import { eodTranslations, eodListItemAutocomplete, eodObjectAutocomplete, MeasurementType, eodColorPicker } from '@/eodvuecomponents';
import eodIconPicker from './eod-icon-picker.vue';
import eodListItems from './eod-list-items.vue';
import { CELL_GROUPS } from './../models/dashboard';

export default {
    name: 'eod-measurementType',
    components: {
        'eod-list-items': eodListItems,
        'eod-translations': eodTranslations,
        'eod-listitem-autocomplete': eodListItemAutocomplete,
        'eod-object-autocomplete': eodObjectAutocomplete,
        eodColorPicker,
        eodIconPicker
    },
    props: {
        value: Object
    },
    data: () => {
        return {
            MeasurementType: MeasurementType,
            saving: false,
            settings: require('@/eodvuecomponents/src/classes/settings.js'),
            rules: {
                required: (value, fieldName) => !!value || (fieldName?fieldName:'Veld')+' is verplicht.',
            },
            listTypes: ['scale'],
            visibleDashboardCellTypes: [],
            listItems: null,
            activeTab:0,
            itemType: 'number',
            cellTypes: CELL_GROUPS.graphs.cellTypes
        }
    },
    created() {
        let val = this.value;
        if (!val.dashboardConfig) {
            val.dashboardConfig = {
                graphs: ['tile']
            };
        }

        if(!val.typeConfig){
            val.typeConfig = {}
        }

        if (!val.metricConfig) {
            val.metricConfig = {
                dataType: 'FLOAT',
                factor: 1,
                offset: 0,
                interval:900,
                method:'POLLED'
            };
        }

        if (!val.id) {
            val.unit = {
                id: null
            }
            val.formConfig = {
                enabled: true,
                type: 'number'
            }
        }

        if(val.formConfig && val.formConfig.type){
            this.itemType = val.formConfig.type;
        }

        if(val.valueConfigList){
            this.changeListItems(val.valueConfigList);
        }

        this.$emit('input', val);
    },
    methods: {
        updateProperty(data){
            let val = this.value;
            val = _.merge(val, data);
            this.$emit('input', val);
        },
        visibleListitems(){
            const items = [];
            for (let i = 0; i < this.listItems.length; i++) {
                const listItem = this.listItems[i];
                if(!listItem.isDeleted){
                    items.push(listItem);
                }
            }
            return items;
        },
        deleteListItem(index){
            const val = this.value;
            
            if(this.listItems[index].id){
                this.listItems[index].isDeleted = true;
            }else{
                this.listItems.splice(index, 1);
            }

            val.valueConfigList.listItems = this.listItems;
            
            this.$emit('input', val);
            this.$forceUpdate();
        },
        createNewList(){
            const val = this.value;
            val.valueConfigList = {
                itemType:'VALUE_TO_TEXT',
                listItems: [
                    {
                        threshold: 1,
                        name: null
                    }
                ]
            }
            this.changeListItems(val.valueConfigList);
            this.$emit('input', val);
        },
        addListItem(){
            // Get next value
            const val = this.value;
            let max = 0;
            for (let i = 0; i < this.listItems.length; i++) {
                const listItem = this.listItems[i];
                if(listItem.threshold > max){
                    max = listItem.threshold;
                }
            }

            this.listItems.push({
                threshold: max+1,
                name:''
            });

            val.valueConfigList.listItems = this.listItems;
            this.$emit('input', val);
            this.$forceUpdate();
        },
        fieldTypeChanged(fieldType){
            this.itemType = fieldType
            const val = this.value;
                val.valueConfigList = null;
                this.$emit('input', val);
        },
        changeListItems(val){
            this.listItems = null;
            if(val && val.listItems){
                this.listItems = val.listItems;
            }

            this.updateProperty({valueConfigList:val});
        },
        setMetricValue(propertyName, val) {
            const item = this.value;

            item.metricConfig[propertyName] = val;

            this.$emit('input', item);
        },
        getMetricValue(propertyName) {
            if (this.value && this.value.metricConfig) {
                return this.value.metricConfig[propertyName];
            }

            return null;
        },
    }
}
</script>