<template>
    <v-menu v-model="showMenu" offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
            <v-btn class="d-none d-sm-inline-flex" icon v-if="arrows" :disabled="!value" @click="gotoDate(1)"><v-icon>mdi-chevron-right</v-icon></v-btn>
            <v-chip class="ml-2 ml-sm-0" v-on="on" v-bind="attrs" outlined style="min-width:160px;"><v-icon left>mdi-clock-outline</v-icon>{{
                value?value.text: 'Alles'
            }}</v-chip>
            <v-btn class="d-none d-sm-inline-flex" icon v-if="arrows" :disabled="!value" @click="gotoDate(-1)"><v-icon>mdi-chevron-left</v-icon></v-btn>
        </template>
        <v-card min-width="300">
            <v-row>
                <v-col cols="12" md="7">
                    <v-card-title class="subtitle-1">Absoluut</v-card-title>
                    <v-card-text v-if="singleDate">
                        <vc-date-picker :minute-increment="15"
                            :attributes="[{ key: 'today', highlight: { color: 'orange', fillMode: 'solid', class: 'primary' }, dates: new Date() }]"
                            class="flex-grow-1" v-model="from" @input="updateFrom" mode="date" is24hr
                            :model-config="{ type: 'string', mask: 'DD-MM-YYYY' }">
                            <template v-slot="{ inputValue, inputEvents, updateValue }">
                                <v-text-field class="mb-4" label="Datum" hide-details placeholder="dd-mm-jjjj"
                                    :value="inputValue"
                                    @input="updateValue($event, { formatInput: false, hidePopover: false, debounce: 1000 })"
                                    @change="updateValue($event, { formatInput: true, hidePopover: false, })"
                                    v-on="inputEvents" dense outlined></v-text-field>
                            </template>
                        </vc-date-picker>
                    </v-card-text>
                    <v-card-text v-else>
                        <vc-date-picker :minute-increment="15"
                            :attributes="[{ key: 'today', highlight: { color: 'orange', fillMode: 'solid', class: 'primary' }, dates: new Date() }]"
                            class="flex-grow-1" v-model="from" @input="updateFrom" mode="dateTime" is24hr
                            :model-config="{ type: 'string', mask: 'DD-MM-YYYY HH:mm' }">
                            <template v-slot="{ inputValue, inputEvents, updateValue }">
                                <v-text-field class="mb-4" label="Van" hide-details placeholder="dd-mm-jjjj"
                                    :value="inputValue"
                                    @input="updateValue($event, { formatInput: false, hidePopover: false, debounce: 1000 })"
                                    @change="updateValue($event, { formatInput: true, hidePopover: false, })"
                                    v-on="inputEvents" dense outlined></v-text-field>
                            </template>
                        </vc-date-picker>
                        <vc-date-picker :minute-increment="15"
                            :attributes="[{ key: 'today', highlight: { color: 'orange', fillMode: 'solid', class: 'primary' }, dates: new Date() }]"
                            class="flex-grow-1" v-model="until" @input="updateUntil" mode="dateTime" is24hr
                            :model-config="{ type: 'string', mask: 'DD-MM-YYYY HH:mm' }">
                            <template v-slot="{ inputValue, inputEvents, updateValue }">
                                <v-text-field label="Tot" hide-details placeholder="dd-mm-jjjj" :value="inputValue"
                                    @input="updateValue($event, { formatInput: false, hidePopover: false, debounce: 1000 })"
                                    @change="updateValue($event, { formatInput: true, hidePopover: false, })"
                                    v-on="inputEvents" dense outlined></v-text-field>
                            </template>
                        </vc-date-picker>
                    </v-card-text>
                </v-col>
                <v-col cols="12" md="5">
                    <v-card-title class="pb-0 subtitle-1">Relatief</v-card-title>
                    <v-list dense>
                        <v-list-item :class="(date && timeRange.text == date.text) ? 'grey lighten-2' : ''"
                            v-for="timeRange in timeRanges" @click="setRelativeTime(timeRange)" :key="timeRange.text">
                            <v-list-item-content>
                                <v-list-item-title>{{ timeRange.text }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-card-actions>
                <v-btn v-if="!hideClear" @click="clear" small text color="error">Wissen</v-btn>
                <v-btn @click="showMenu = false" small text>Annuleren</v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="save" color="secondary" small rounded depressed v-if="!singleDate">Instellen</v-btn>
            </v-card-actions>
        </v-card>
    </v-menu>
</template>
<style lang="scss" scoped>
.v-menu__content {
    overflow: visible;
    contain: none;
}
</style>
<script>
export default {
    name: 'eod-time-selector',
    props: {
        value: Object,
        singleDate: {
            type: Boolean,
            default: false
        },
        hideClear: {
            type: Boolean,
            default: false
        },
        arrows: {
            type: Boolean,
            default: false
        },
        noUrlUpdate: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        showMenu: false,
        date: { text: '' },
        from: null,
        until: null,
        relativeTimeRanges: require('./../classes/settings').relativeTimeRanges,
    }),
    mounted() {
        if(this.$route && this.$route.query.time_range){
            const time_range = this.$route.query.time_range;
            if(time_range.indexOf('|') > -1){
                const parts = time_range.split('|');
                this.from = this.$moment.unix(parts[0]).format('DD-MM-YYYY HH:mm');
                this.updateFrom(this.from);
                this.until = this.$moment.unix(parts[1]).format('DD-MM-YYYY HH:mm');
                this.updateUntil(this.until);
                this.save();
            } else {
                const value = time_range.match(/-?\d+/g)[0];
                const type = time_range.match(/[a-zA-Z]+/g)[0];
                for (let i = 0; i < this.timeRanges.length; i++) {
                    const range = this.timeRanges[i];
                    if(range.value == value && range.type == type){
                        
                        this.setRelativeTimeByIndex(i);
                        break;
                    }
                }
            }
        }else if (this.value && this.value.relative) {
            this.setRelativeTime(this.value.relative);
            this.save();
        } else if (this.value) {
            this.date = this.value;
            this.setPopupDates();
        }
    },
    computed: {
        timeRanges() {
            let ranges = [];
            for (let i = 0; i < this.relativeTimeRanges.length; i++) {
                const timeRange = this.relativeTimeRanges[i];

                if (this.singleDate) {
                    if (timeRange.singleDate) {
                        ranges.push(timeRange);
                    }
                } else {
                    ranges.push(timeRange);
                }
            }

            return ranges;
        }
    },
    methods: {
        clear() {
            this.date = null;
            this.save();
        },
        setPopupDates() {
            if (this.singleDate) {
                this.from = this.date.from.format('DD-MM-YYYY');
                this.until = this.date.until.format('DD-MM-YYYY');
            } else {
                this.from = this.date.from.format('DD-MM-YYYY HH:mm');
                this.until = this.date.until.format('DD-MM-YYYY HH:mm');
            }
        },
        gotoDate(step) {
            this.date.relative = null;

            const diff = this.date.until.diff(this.date.from, 'days');
            step = diff != 0 ? (step * (diff+1)) : step;

            this.date.from.add(step, 'days');
            this.date.until.add(step, 'days');

            if (this.singleDate) {
                this.date.text = this.date.from.format('DD-MM-YYYY');
            } else {
                this.date.text = this.date.from.format('DD-MM-YYYY') + ' - ' + this.date.until.format('DD-MM-YYYY');
            }

            this.setPopupDates();

            this.$nextTick(() => {
                this.save();
            });

        },
        updateFrom(from) {
            if (from) {
                if (this.singleDate) {
                    this.date = {
                        relative: null,
                        from: this.$moment(from, 'DD-MM-YYYY').startOf('day'),
                        until: this.$moment(from, 'DD-MM-YYYY').endOf('day'),
                    }

                    this.date.text = from;

                    this.save();
                } else {
                    this.date = {
                        relative: null,
                        from: this.$moment(from, 'DD-MM-YYYY HH:mm'),
                        until: this.date.until,
                    }

                    this.date.text = from + ' - ' + (this.until ? this.until : '/');
                }
            }
        },
        updateUntil(until) {
            if (until) {
                this.date = {
                    relative: null,
                    from: this.date.from,
                    until: this.$moment(until, 'DD-MM-YYYY HH:mm'),
                }

                this.date.text = (this.from ? this.from : '/') + ' - ' + until;
            }
        },
        setRelativeTimeByIndex(index) {
            this.setRelativeTime(this.timeRanges[index]);
        },
        setRelativeTime(timeRange) {
            //this.date.relative = timeRange;

            this.from = null;
            this.until = null;

            this.date = {
                relative: timeRange,
                text: timeRange.text
            }

            if(timeRange.closest){
                if (timeRange.value >= 0) {
                    this.date.from = this.$moment().startOf(timeRange.closest);
                    this.date.until = this.$moment().add(timeRange.value, timeRange.type).endOf(timeRange.closest);
                } else {
                    this.date.from = this.$moment().add(timeRange.value, timeRange.type).startOf(timeRange.closest);
                    this.date.until = this.$moment().endOf(timeRange.closest);
                }
            }else if (timeRange.absolute){
                this.date.from = this.$moment().add(timeRange.value, timeRange.type).startOf(timeRange.closest);
                this.date.until = this.$moment().add(timeRange.value, timeRange.type).endOf(timeRange.closest);
            } else {
                if (timeRange.value >= 0) {
                    this.date.from = this.$moment();
                    this.date.until = this.$moment().add(timeRange.value, timeRange.type);
                } else {
                    this.date.from = this.$moment().add(timeRange.value, timeRange.type);
                    this.date.until = this.$moment();
                }
            }

            this.save();
        },
        save() {

            if(this.$route && !this.noUrlUpdate){
                let queryDate = null;
                if(this.date){
                    queryDate = this.date.from.unix() + '|' + this.date.until.unix();
                    if(this.date.relative){
                        queryDate = this.date.relative.value+this.date.relative.type;
                    }
                }
                
                const query = this.$route.query;
                if(queryDate){
                    query.time_range = queryDate;
                } else {
                    delete query.time_range;
                }
                
                const routeVar = {name:this.$route.name, params:this.$route.params, query:query};
                const route = this.$router.resolve(routeVar).route;
                window.history.pushState({}, null, route.fullPath);

                if(query.time_range){
                    localStorage.setItem('eyeondata.routes.'+this.$route.name+'.time_range', query.time_range);
                } else {
                    localStorage.removeItem('eyeondata.routes.'+this.$route.name+'.time_range');
                }
                
            }

            this.$emit('input', this.date);
            this.showMenu = false;
        }
    }
}
</script>